import { axiosAuth } from '../../axiosAuth';
let data = require('../../config.js');
let url = data.url;

export const login = async(data,userType)=>{
    try {
        let response;
        if(userType === 'ADMIN'){
             response = await axiosAuth.post( '/rest/api/v1/admin/login',data);
        } else {
             response = await axiosAuth.post( '/rest/api/v1/influencer/login',data);
        }
        return response;
    } catch (error) {
        return error.response;
    }
}

export const me = async(userId, userType) => {
    try {
        let response;
        if(userType === 'ADMIN') {
            response = await axiosAuth.get( '/rest/api/v1/admin/'+userId);
             
        } else {
            response = await axiosAuth.get( '/rest/api/v1/influencer/'+userId)
        }
        return response;
    } catch (error) {
        return error.response;
    }
}

export const forgotPass = async(email)=>{
    try {
        let response  = await axiosAuth.get( `/rest/api/v1/influencer/forgot-password?email=${email}`,data);
        return response;
    } catch (error) {
        return error.response;
    }
}