
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword } from './influencer.http';
import { Toast } from 'primereact/toast';

import "./influencers.scss"

export const ForgotPassword = () => {
    const [password,setPassword] = useState('');
    const [confirmPassword,setConfirmPassword] = useState('');
    const params = useParams();
    const toast = useRef(null);
    const navigate = useNavigate();
    const resetPasswordCall = async()=>{
        let response =await resetPassword(params.influencerId,params.forgotPasswordId,password);
        if(response.status === 200){
            await toast.current.show({ severity: 'success', summary: 'Password Reset Successfully !!', life: 3000 });
            await setTimeout(()=>{
              navigate('/');
            },3000)
        } else {
            await toast.current.show({ severity: 'error', summary: 'Invalid Request !!', life: 3000 });
        }
    }

  return (
    <div className='forgot-password'>
        <Toast ref={toast}></Toast>
        <div>
            <h3>Reset Your password</h3>
            <InputText type="password" placeholder='New Password' className='password-field' value={password} onChange={(e)=>setPassword(e.target.value)}></InputText>
            <InputText type="password" placeholder='Confirm Password' className='password-field' validateOnly={confirmPassword} onChange={(e)=> setConfirmPassword(e.target.value)}></InputText>
            { password !== confirmPassword && confirmPassword.length>0 ?<div className='password-error'>Both Passwords should match and should be greater than 6 characters</div> :<div></div>}
            <Button label='Reset Password' className='password-field p-button-danger' onClick={async ()=>{
                await resetPasswordCall();
            }} disabled={(password !== confirmPassword) || password.length<6}></Button>

        </div>
    </div>
  )
}
