import axios from 'axios';

export const axiosAuth = axios.create();

//Intercept All http requests
axiosAuth.interceptors.request.use(async function(config){
    config['headers']['authorization'] = 'Bearer ' + localStorage.getItem('authToken');
    return config;
}, error => {
    return Promise.reject(error);
});

axiosAuth.interceptors.request.use(async function(config){
    return config;
}, error => {
    return Promise.reject(error);
});