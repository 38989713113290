import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import { addCampaign, downloadFile, getCampaigns } from './admin.http';
import { Column } from 'primereact/column';
import { useNavigate } from 'react-router-dom';
import { Editor } from 'primereact/editor';
import { RadioButton } from 'primereact/radiobutton';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

export const Campaign = () => {
    const [campaignForm, ShowCampaignForm] = useState(false);
    const [campaigns, setCampaigns] = useState([]);
    const [deliverables,setDeliverables] = useState(null);
    const [deliverableType, setDeliverableType] = useState('HTML');
    const [selectedFile, setSelectedFile] = useState('');
    const [termsDialog, showTermsDialog] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState({});
    const [numPages, setNumPages] = useState(null);
    const [pdf, setPdf] = useState('');
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
        },
        validate: (data)=>{
            let errors = {};
            if (!data.name) {
                errors.name = "Invalid Name";
            }
            if (!data.description) {
                errors.description = "Invalid Description";
            }
            return errors;
        },
        onSubmit: async (data) => {
            let response;
            if(deliverableType === 'FILE'){
                const formData = new FormData();
                formData.append(
                    "file",
                    selectedFile,
                    selectedFile.name
                );
                const { name, description } = formik.values;
                formData.append("name", name);
                formData.append("description", description);
                formData.append("deliverableType", deliverableType);
                response = await addCampaign(formData);
            } else {
                data['deliverables'] =deliverables;
                data['deliverableType'] = deliverableType;
                response = await addCampaign(data);
            }
            if (response.status === 200) {
                ShowCampaignForm(false);
                let campaignData = await getCampaigns();
                setCampaigns(campaignData.data);
            }
            formik.resetForm();
        }
    })

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    useEffect(() => {
        (async () => {
            let response = await getCampaigns();
            if (response.status === 200) {
                setCampaigns(response.data);
            }
        })();
    }, [])
    const header = (
        <span className="ql-formats">
            <button className="ql-bold" aria-label="Bold"></button>
            <button className="ql-italic" aria-label="Italic"></button>
            <button className="ql-underline" aria-label="Underline"></button>
        </span>
    );

    const onFileChange = event => {
        setSelectedFile(event.target.files[0]);
    };

    return (
        <div className='p-8'>

            <div className='pb-4'>
                <Button label='Create Campaign' className='p-button-danger' onClick={() => ShowCampaignForm(true)}></Button>
            </div>

            <div>
                <DataTable value={campaigns} selectionMode="single" onSelectionChange={(e) => navigate(`/admin/campaign/${e.value.id}`)}>
                    <Column header="Name" field='name'></Column>
                    <Column header="Description" field='description'></Column>
                    <Column  header='Deliverables' body={(rowData)=>{
                            return <Button label='View' className='p-button-rounded p-button-danger' onClick={ async()=> {
                                showTermsDialog(true);
                                setSelectedCampaign(rowData);
                                if(rowData.deliverableType === 'HTML'){
                                    var temp = document.createElement('div');
                                    temp.innerHTML = rowData.deliverables;
                                    var htmlObject = temp.firstChild;
                                    setTerms(htmlObject);
                                } else {
                                    let response = await downloadFile(rowData.deliverables);
                                    let file = await fetch(`data:application/pdf;base64,${response.data}`)
                                    file = await file.blob();
                                    file = new File([file], 'deliverables.pdf',{type : 'application/pdf'});
                                    setPdf(await file.arrayBuffer());
                                }
                            }}></Button>
                        }}></Column>
                </DataTable>
            </div>
            <div>
                <Dialog visible={campaignForm} onHide={() => ShowCampaignForm(false)} style={{width : '70%'}} >
                    <div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="p-fluid p-formgrid p-grid">

                                <div className="p-field p-col m-2">
                                    <label htmlFor="campaign">Campaign name</label>
                                    <InputText
                                        id="name"
                                        name="name"
                                        type="name"
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                    />
                                    {getFormErrorMessage("name")}
                                </div>

                                <div className="p-field p-col m-2">
                                    <label htmlFor="description">Description</label>
                                    <InputText
                                        id="description"
                                        name="description"
                                        type="text"
                                        onChange={formik.handleChange}
                                        value={formik.values.description}
                                    />
                                    {getFormErrorMessage("description")}
                                </div>
                                <div className="p-field p-col m-2">
                                    <label htmlFor="deliverables">Deliverables</label>
                                    <div className='flex'>
                                        <div className="field-radiobutton">
                                            <RadioButton inputId="html" name="html" value="HTML" onChange={(e) => setDeliverableType(e.value)} checked={deliverableType === 'HTML'} />
                                            <label htmlFor="html">HTML</label>

                                        </div>
                                        <div className="field-radiobutton pl-4">
                                            <RadioButton inputId="file" name="file" value="FILE" onChange={(e) => setDeliverableType(e.value)} checked={deliverableType === 'FILE'} />
                                            <label htmlFor="file">File</label>
                                        </div>
                                    </div>

                                    {deliverableType === 'HTML' ? <Editor style={{height:'320px'}} 
                                    id="deliverables"
                                    name="deliverables"
                                    onTextChange={(e) => { setDeliverables(e.htmlValue)}}
                                    value={deliverables}
                                    /> : <div>
                                            <input type='file' required name='deliverables' id='deliverables' onChange={onFileChange} accept=".pdf"/>
                                        </div>}

                                </div>
                                {getFormErrorMessage("deliverables")}

                                <Button label='Add' onClick={formik.handleSubmit}> </Button>
                            </div>
                        </form>
                    </div>
                </Dialog>
                <Dialog visible={termsDialog} onHide={() => showTermsDialog(false)} style={{width : "70%"}} >
                {selectedCampaign.deliverableType === 'HTML' ? <div dangerouslySetInnerHTML={{__html: selectedCampaign.deliverables}} /> : 
                <div style={{ width: "100%" }}>
                        <header className="App-header" >
                            <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
                                {Array.from(new Array(numPages), (el, index) => (
                                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                                ))}
                            </Document>

                        </header>
                        <Button label='Download' className='m-4' onClick={() => {
                            const file = new Blob([pdf], { type: 'application/pdf' });
                            const fileURL = URL.createObjectURL(file);
                            const link = document.createElement("a");
                            link.href = fileURL;
                            link.download = "terms-and-condition.pdf";
                            link.click();
                        }}  ></Button>
                </div>}
			</Dialog>

            </div>

        </div>
    )
}
