import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react'
import { addCategory, deleteCategory, fetchAllCategories } from './admin.http';

export const Category = () => {

	const [categories, setCategories] = useState([]);
	const [showCategoryForm,setShowCategoryForm] = useState(false);
	const [name,setName] = useState('');

	useEffect( () =>{
		(async () =>{
			let response = await fetchAllCategories();
			setCategories(response.data);
		})();
	},[])
	return (
		<div className='p-8'>
			<Button label='Add Category' className='p-button-danger' onClick={() => setShowCategoryForm(true) } ></Button>
			<DataTable value={categories}>
				<Column field='name' header='Name'></Column>
				<Column header='Action' body={(rowData) => {
					return <Button label='Delete' className='p-button-rounded p-button-danger' onClick={async () => {
						await deleteCategory(rowData.id)
					}}></Button>
				}}></Column>
			</DataTable>
			<Dialog visible={showCategoryForm} onHide={() => { setShowCategoryForm(false) }}>
				<div>
					<InputText placeholder='Add Category' onChange={(e) => setName(e.target.value) }></InputText>
					<Button label='Add' className='p-button-success p-button-rounded mt-2' onClick={async()=>{
						await addCategory(name);
						setShowCategoryForm(false);
						setName('');
					}}></Button>
				</div>
			</Dialog>

		</div>
	)
}
