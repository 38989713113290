import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Button } from 'primereact/button';
import { getCampaignInvoices, sendInvoice } from './invoice.http';

export const Invoices = () => {
    const params = useParams();
    let [influencers,setInfluencer] = useState([]);

    useEffect(() => {
        (async () => {
           let response = await getCampaignInvoices(params.id);
            setInfluencer(response.data.invoices);
        })();
    }, []);

    const sendInvoiceEmail = async(body)=>{
        let response = await sendInvoice(body);
        alert("Email Sent");
    }

    console.log(influencers);
    return (
        <div style={{margin:"5rem",minHeight:"100vh"}}>
            <DataTable value={influencers} header="Invoices" responsiveLayout="scroll">
                    <Column field='userName' header="User" sortable={true} ></Column>
                    <Column field='email' header="Email" sortable={true} ></Column>
                    <Column body={(rowData)=>{
                        return <Button label='Send Invoice'  className='p-button-primary' onClick={async()=>{
                            await sendInvoiceEmail({influencerId : rowData.influencerId, campaignId: params.id}); 
                        }}></Button>
                    }}></Column>
                    <Column body={(rowData)=>{
                        return <Button label='Download Invoice' visible={rowData.status==='UPLOADED'} className='p-button-primary'
                        onClick={()=>{
                            const link = document.createElement("a");
                            link.href = `${window.SERVER_DATA.CDN_URL}/marketing-tech/inst-campaign-mgmt/` + rowData.invoicePath;
                            link.target="_blank"
                            link.click();
                            document.body.append(link);
                        }}
                        ></Button>
                    }}></Column>
            </DataTable>

        </div>
    )
}
