import { axiosAuth } from '../../axiosAuth';

let data = require('../../config.js');
let url = data.url;

export const getCampaingDetailsForUser = async(userId) =>{
    try {
        let resposne = await axiosAuth.get('/rest/api/v1/influencer/campaigns/' + userId );
        return resposne;
    } catch (error) {
        return error.response;
    }
}

export const acceptCampaignInvite = async(campaignId,influencer)=>{
    try {
        let response  = await axiosAuth.put( '/rest/api/v1/campaign/accept/'+campaignId,influencer);
        return response;
    } catch (error) {
        return error.response;
    }
}

export const getInfluencerDetails = async(influencerId) => {
    try {
        let response = await axiosAuth.get( '/rest/api/v1/influencer/'+influencerId);
        return response;
    } catch (error) {
        return error.response;
    }
}

export const termsAndConditions  = async(data)=>{
    try {
        let response = await axiosAuth.request({
            method : 'POST',
            data : data,
            url :  '/rest/api/v1/influencer/terms',
            responseEncoding: 'binary',
            responseType : 'arraybuffer'
        });
        return response;
    } catch (error) {
        return error.response;
    }
}
export const viewTermsAndConditions  = async(data)=>{
    try {
        let response = await axiosAuth.request({
            method : 'POST',
            data : data,
            url :  '/rest/api/v1/influencer/view-terms',
            responseEncoding: 'binary',
            responseType : 'arraybuffer'
        });
        return response;
    } catch (error) {
        return error.response;
    }
}
export const uploadTermsAndConditions  = async(formData,id)=>{
    try {
        let response = await axiosAuth.request({
            method : 'POST',
            data : formData,
            url :  '/rest/api/v1/influencer/onboard/upload/'+id,
            headers: { "Content-Type": "multipart/form-data" },
        });
        return response;
    } catch (error) {
        return error.response;
    }
}

export const postContent = async(formData)=>{
    try {
        let response = await axiosAuth.request({
            method : 'POST',
            data : formData,
            url :  '/rest/api/v1/campaign/post',
            headers: { "Content-Type": "multipart/form-data" },
        });
        return response;
    } catch (error) {
        return error.response;
    }
}

export const fetchCampaignContentDetails = async(data)=>{
    try {
        let response = await axiosAuth.post( "/rest/api/v1/campaign/fetch",data);
        return response;
    } catch (error) {
        return error.response;
    }
}

export const postStoryDetails = async(formData)=>{
    try {
        let response = await axiosAuth.request({
            method : 'POST',
            data : formData,
            url :   '/rest/api/v1/campaign/post-story',
            headers: { "Content-Type": "multipart/form-data" },
        });
        return response;
    } catch (error) {
        return error.response;
    }
}

export const updateContentDetails = async(formData)=>{
    try {
        let response = await axiosAuth.request({
            method : 'PUT',
            data : formData,
            url :   '/rest/api/v1/campaign/post',
            headers: { "Content-Type": "multipart/form-data" },
        });
        return response;
    } catch (error) {
        return error.response;
    }
}

export const uploadPostLink = async(data)=>{
    try {
        let response = await axiosAuth.post( "/rest/api/v1/campaign/post-link",data);
        return response;
    } catch (error) {
        return error.response;
    }
}
export const uploadPostAnalytics = async(data)=>{
    try {
        let response = await axiosAuth.post( "/rest/api/v1/campaign/post-analytics",data);
        return response;
    } catch (error) {
        return error.response;
    }
}

export const resetPassword = async(userId,id,password)=>{
    try {
        let response = await axiosAuth.post( `/rest/api/v1/influencer/reset-password?userId=${userId}&id=${id}`,{password});
        return response;
    } catch (error) {
        return error.response;
    }
}

export const uploadInvoice = async(formData)=>{
    try {
        let response = await axiosAuth.request({
            method : 'POST',
            data : formData,
            url :   '/rest/api/v1/invoices/upload-invoice',
            headers: { "Content-Type": "multipart/form-data" },
        });
        return response;
    } catch (error) {
        return error.response;
    }
}
