import React from 'react'
import { Menubar } from 'primereact/menubar';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authStateActions } from '../../store';
export const Navbar = () => {
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const start = <img alt="logo" src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png"  height="35" className="mr-2"></img>;
    const end = <div className=''>
        <i className='pi pi-fw pi-power-off mr-2 '></i>
        <span className='mr-2' onClick={ () =>{ 
        localStorage.removeItem('authToken');
        localStorage.removeItem('userId');
        localStorage.removeItem('userType');
        dispatch(authStateActions.setAuthState({
            isAuthenticated: false,
            authToken: null,
            userId: null
          }))
        navigate('/login')
    }}>Logout</span>
    </div>
    const items = [
        {
            label : 'Influencers',
            command: () => { navigate('/admin/influencers')}
        },
        {
            label : 'Campaigns',
            command: () => { navigate('/admin/campaigns')}
        },
        {
            label : 'Categories',
            command: () => { navigate('/admin/category')}
        },
        {
            label : 'Referral',
            command: () => { navigate('/admin/referral')}
        },
    ]
    return (
        <Menubar style={{background:"black", color:"white"}}
            model={items} start={start} end={end}
        /> 
    )
}
