import React, { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { fetchAllCategories, influencerOnboarding, influencerRegister } from '../admin-dashboard/admin.http';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getInfluencerDetails, termsAndConditions, uploadTermsAndConditions, viewTermsAndConditions } from './influencer.http';
import { Checkbox } from 'primereact/checkbox';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import "./influencers.scss"
import { MultiSelect } from 'primereact/multiselect';
import { ProgressSpinner } from 'primereact/progressspinner';

export const OnboardingForm = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [influencerEmail, setInfluencerEmail] = useState('');
    const [checked, setChecked] = useState(false);
    const toast = useRef(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [showPdf, setShowPdf] = useState(false);
    const [pdf, setPdf] = useState('')
    const [selectedFile, setSelectedFile] = useState('');
    const [categories ,setCategories] = useState('');
    const [selectedCategories,setSelectedCategories] = useState([]);
    const [infoData,setInfoData] = useState(false);
    const [viewTerms,setViewterms] = useState(false);
    const [showError,setShowError] = useState(false);
    const [loader,showLoader] = useState(false);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    const formik = useFormik({
        initialValues: {
            fullname: '',
            email: '',
            password: '',
            address: '',
            igUserName : '',
            igFollowers: ''

        },
        validate: (data) => {
            let errors = {};
            if (!data.fullname) {
                errors.fullname = "Name is required",
                    errors.email = "Email is required",
                    errors.password = "Password is required",
                    errors.address = "Address is required"
            }
            if(!(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(data.email)){
                errors.email= "Email is not valid";
            }
            if(!params.id && !data.igFollowers){
                errors.igFollowers = 'Number of followers are required'
            }
            if(!params.id && !data.igUserName){
                errors.igUserName = 'Number of followers are required'
            }
            return errors;
        },
        onSubmit: async (values) => {
            if(!viewTerms){
                setShowError(true);
                await toast.current.show({ severity: 'error', summary: 'Please view terms and conditions with your filled information', life: 3000 });
            } else{
                showLoader(true);
                let response;
                values['categories'] = selectedCategories;
                if(params.id){
                    values['id'] = params.id;
                    response = await influencerOnboarding(values);
                } else{
                    let query = new URLSearchParams(location.search);
                    let referralId = query.get('referral');
                    values['referralId'] = referralId;
                    response = await influencerRegister(values);   
                }
                if (response.status === 200) {
                    let file = new File([pdf], `${params.id}-terms-and-conditions.pdf`);
                    let data = new FormData();
                    data.append('file', file, file.name);
                    let fileResponse = await uploadTermsAndConditions(data, params.id ? params.id : response.data.id);
                    await toast.current.show({ severity: 'success', summary: 'Registered Successfully', life: 3000 });
                    setTimeout(() => {
                        navigate('/login')
                    }, 1500)
                } else{
                    await toast.current.show({ severity: 'error', summary: response?.data?.message, life: 3000 });
                }
                showLoader(false);
            }
        },
    });

    useEffect(() => {
        (async () => {
            let categoryResponse = await fetchAllCategories();
            setCategories(categoryResponse.data);
            console.log("Params",params.id);
            if(params.id){
                let response = await getInfluencerDetails(params.id);
                if(response.status !== 200){
                    navigate('/login');
                }
                formik.values.email = response.data.email;
                setInfluencerEmail(response.data.email);
            }
            
        })();
    }, [influencerEmail])
    const onFileChange = event => {
        setSelectedFile(event.target.files[0]);
    };

    const getTermsAndConditions = async () => {
        const formData = new FormData();
        if(formik.values.fullname === '' || formik.values.password === '' || formik.values.address === ''){
            let response = await viewTermsAndConditions(formData);
            setShowPdf(true);
            setPdf(response.data);
        } else{
            formData.append(
                "myFile",
                selectedFile,
                selectedFile.name
            );
            const { fullname, pan, address } = formik.values;
            formData.append("name", fullname);
            formData.append("address", address);
            formData.append("date", (new Date()).toDateString());
    
            let response = await termsAndConditions(formData);
            setShowPdf(true);
            setPdf(response.data);
            setViewterms(true);
        }
        
    }

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    return (
        <div style={{backgroundColor:"#F5F6F6"}} >
            <div style={{display : loader ? 'block' : 'none', zIndex:'1' , width:"100%", height:"100%", background:"white",opacity:"0.5",position:"absolute"}}>
                <ProgressSpinner style={{top: '30%' , left : "50%"}}/>
            </div>
            <div className='p-4'>
                <img src='https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png' height={40} width={100}></img>
            </div>

            <div className='p-2' >
                <Toast ref={toast} />

                <Dialog visible={showPdf} onHide={() => setShowPdf(false)} style={{ width: "100%" }}>
                    <header className="App-header" style={{ width: "100%" }}>
                        <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess} style={{width:"20%"}}>
                            {Array.from(new Array(numPages), (el, index) => (
                                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                            ))}
                        </Document>

                    </header>
                    <Button label='Download' className='m-4' onClick={() => {
                        const file = new Blob([pdf], { type: 'application/pdf' });
                        const fileURL = URL.createObjectURL(file);
                        const link = document.createElement("a");
                        link.href = fileURL;
                        link.download = "terms-and-condition.pdf";
                        link.click();
                    }}  ></Button>
                </Dialog>
                <div style={{ "display": "flex", "flexDirection": "row" }}>
                    <h2 className='welcome-text'>Welcome to Glance Affiliate Program !!</h2>

                </div>

                <form onSubmit={formik.handleSubmit} className="form-flex">
                    <div className='flex-container left-padding ' >
                        <div className="p-field p-col m-2" >
                            <label htmlFor="fullname" ><b>Name</b></label>
                            <InputText
                                id="fullname"
                                name="fullname"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.fullname}
                                className={classNames({
                                    "p-invalid": isFormFieldValid("fullname"),
                                })}
                                style={{ borderRadius: "10px", width: "90%" }}
                            />
                            {getFormErrorMessage("fullname")}
                        </div>

                        
                        <div className="p-field p-col m-2">
                            <label htmlFor="email"><b>Email</b></label>
                            <InputText
                                id="email"
                                name="email"
                                type="email"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                disabled={influencerEmail.length>0}
                                className={classNames({
                                    "p-invalid": isFormFieldValid("email"),
                                })}
                                style={{ borderRadius: "10px", width: "90%" }}
                            />
                            {getFormErrorMessage("email")}
                        </div>

                        <div className="p-field p-col m-2" style={{display: params.id ? 'none' : 'block'}}>
                            <label htmlFor="igUsername"><b>Instagram User Name</b></label>
                            <InputText
                                id="igUsername"
                                name="igUserName"
                                onChange={formik.handleChange}
                                value={formik.values.igUserName}
                                className={classNames({
                                    "p-invalid": isFormFieldValid("igUserName"),
                                })}
                                style={{ borderRadius: "10px", width: "90%" }}
                            />
                            {getFormErrorMessage("igUserName")}
                        </div>
                    </div>
                    <div className='flex-container right-padding' >
                        
                        {/* <div className="p-field p-col m-2">
                            <label htmlFor="category"><b>Interests</b></label>
                            <MultiSelect name="category" id='category' optionLabel="name" optionValue='id'  options={categories} value={selectedCategories} onChange={(e) => setSelectedCategories(e.value)}  style={{ borderRadius: "10px", width: "90%" }}  />
                            {getFormErrorMessage("category")}
                        </div> */}
                        
                        <div className="p-field p-col m-2">
                            <label htmlFor="address"><b>Password (Create a password for your login)</b></label>
                            <InputText
                                id="password"
                                name="password"
                                type="password"
                                onChange={formik.handleChange}
                                value={formik.values.password}
                                className={classNames({
                                    "p-invalid": isFormFieldValid("password"),
                                })}
                                style={{ borderRadius: "10px", width: "90%" }}
                            />
                            {getFormErrorMessage("password")}
                        </div>

                        <div className="p-field p-col m-2" style={{display: params.id ? 'none' : 'block'}}>
                            <label htmlFor="igFollowers"><b>Instagram Followers</b></label>
                            <InputText
                                id="igFollowers"
                                name="igFollowers"
                                type='number'
                                onChange={formik.handleChange}
                                value={formik.values.igFollowers}
                                className={classNames({
                                    "p-invalid": isFormFieldValid("igFollowers"),
                                })}
                                style={{ borderRadius: "10px", width: "90%" }}
                            />
                            {getFormErrorMessage("igFollowers")}
                        </div>

                        <div className="p-field p-col m-2">
                            <label htmlFor="address"><b>Current Address</b></label>
                            <InputTextarea
                                id="address"
                                name="address"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.address}
                                className={classNames({
                                    "p-invalid": isFormFieldValid("address"),
                                })}
                                style={{ borderRadius: "10px", width: "90%" }}
                            />
                            {getFormErrorMessage("address")}
                        </div>
                    </div>
                    <div className='flex-container left-padding mb-4 ml-2 '>
                    <div className="p-field p-col mb-4 ">
                            <label htmlFor="sign"><b>Upload Signature : </b> </label>
                            <input id='sign' name='sign' type="file" onChange={onFileChange} required accept='.jpeg,.png' />
                            <small><b>*Please upload signature in .jpeg/.png format</b></small>
                        </div>
                        <u><span className='p-button-success pt-4' onClick={getTermsAndConditions}><a style={{cursor : "pointer"}}><b>Click here to view Terms and Conditions</b></a></span></u>
                        <br>
                        </br>
                            {showError === true ? <span style={{color:"red"}}><small>*click on the terms & conditions again to view your auto-filled document, before registering</small></span>
                         : <span></span>}
                        <br></br>
                        <div className="field-checkbox pt-4">
                            <Checkbox inputId="binary" checked={checked} onChange={e => setChecked(e.checked)} />
                            <label htmlFor="binary">Accept Terms & Conditions</label>
                        </div>
                        <u><span className='p-button-success pt-4' onClick={()=> setInfoData(true)}><a style={{cursor : "pointer"}}><b>Why are we asking your details?</b></a></span></u><br></br><br></br>
                        <Button type="submit" className=' p-button-danger' disabled={!checked} label="Register"></Button>
                    </div>
                    <Dialog visible={infoData} onHide={()=>setInfoData(false)} style={{width:"80%"}}> 
                        <b><p>We are not storing any of your information with us. We are asking you to share the details to help you auto-fill the Terms & Conditions document, which you can then download for yourself. For queries please contact us at collaboratewithus@glance.com</p></b>
                    </Dialog>
                </form>

            </div>
        </div>

    )


}
