import React, { useEffect, useState } from 'react'
import { createReferral, fetchAllInfluencers, getReferralDetailsForUser } from '../admin.http';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Editor } from 'primereact/editor';
import { Dialog } from 'primereact/dialog';
export const Referral = () => {

    const [influencers,setInfluencers] = useState([]);
    const [emailSubject, setEmailSubject] = useState('');
    const [emailBody,setEmailBody] = useState('');
    const [referralDetails,setReferralDetails] = useState(false);
    const [referredInfluencers, setReferredInfluencers] = useState([]);

    useEffect(()=>{
        (async () => {
            let response = await fetchAllInfluencers({ onBoarded: true});
            if (response.status === 200) {
                setInfluencers(response.data);
            }
        })();
    },[])

    const createRef = async(data)=>{
        if(emailSubject.length === 0 || emailBody.length === 0){
            alert("PLease set emails");
            return;
        }
        let body = {
            influencerId : data.id,
            emailSubject,
            emailBody,
            userName : data.userName,
            email : data.email
        };
        let response = await createReferral(body);
        if(response.status === 200){
            alert("Created");
        } else{
            alert("Something went wrong");
        }
    }

    const getRefferedInfluncers = async(userId)=>{
        let response = await getReferralDetailsForUser(userId);
        if(response.status === 200){
            setReferredInfluencers(response.data);
            setReferralDetails(true);
        } else{
            alert("Something went wrong");
        }
    }
    return (
        <div>
            <div className='email'>
                <div className="p-field p-col m-2">
                    <label htmlFor="campaign"><b>Referral Email Subject</b></label>
                    <InputText
                        id="inviteSubject"
                        name="inviteSubject"
                        type="text"
                        style={{ width: "100%" }}
                        onChange={(e)=>{
                            setEmailSubject(e.target.value);
                        }}
                        value={emailSubject}
                    />
                </div>
                <div className="p-field p-col m-2">
                    <label htmlFor="campaign"><b>Referral Email Body</b></label>
                    <Editor style={{ height: '320px' }}
                        id="inviteBody"
                        name="inviteBody"
                        onTextChange={(e)=>{
                            setEmailBody(e.htmlValue);
                        }}
                        value={emailBody}
                    />
                </div>
            </div>
            <DataTable value={influencers} paginator rows={25} filterDisplay="menu">
                    <Column field="userName" header="Username" filter={true} filterMatchMode="contains" ></Column>
                    <Column field="email" header="Email" filter={true} filterMatchMode="contains"></Column>
                    <Column header="" body={(rowData) => {
                        return <Button label='Email' onClick={async() => {
                            await createRef(rowData);
                        }}></Button>
                    }}></Column>
                     <Column header="" body={(rowData) => {
                        return <Button label='View' onClick={async() => {
                            await getRefferedInfluncers(rowData.id);
                        }}></Button>
                    }}></Column>
            </DataTable>


             <Dialog visible={referralDetails} onHide={()=> setReferralDetails(false)}>
                <h1>Total Count: {referredInfluencers.length}</h1>
                <DataTable value={referredInfluencers} paginator rows={25} filterDisplay="menu" >
                    <Column field="userName" header="Username" filter={true} filterMatchMode="contains" ></Column>
                    <Column field="email" header="Email" filter={true} filterMatchMode="contains"></Column>
                </DataTable>
             </Dialog>
        </div>

    )
}
