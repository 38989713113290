import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { campaignAcceptedReminder, campaignInvitationReminder, closeCampaign, getCampaignDetailsById , pushContent, pushStory} from './admin.http'
import { CampaignContent } from './CampaignContent'
import { Influencers } from './Influencers'
import { InviteInfluencers } from './InviteInfluencers'
import { useDispatch, useSelector } from 'react-redux';
import { adminCampaignStateActions } from '../../store/index.js'
import { InputTextarea } from 'primereact/inputtextarea'
import { Carousel } from 'primereact/carousel';
import { Toast } from 'primereact/toast';
import { CampaignEmail } from './CampaignEmail';

export const CampaignDetails = () => {
	const [influencerList, showInfluencerList] = useState(false);
	const [campaignDetails, setCampaignDetails] = useState({});
	const [pushContentModal, showPushContentModal] = useState(false);
	const [pushStoryModal, showPushStoryModal] = useState(false);
	const [caption, setCaption] = useState('');
	const [storyUrl, setStoryUrl] = useState('');
	const [selectedFile,setSelectedFile]  = useState('');
	const [invitedTablePage, setInvitedTablePage] = useState('');
	const [acceptedTablePage, setAcceptedTablePage] = useState('');
	const [campaignEmail, showCampaignEmails] = useState(false);

	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch  = useDispatch();
    const toast = useRef(null);

	let inviteSubject = useSelector(state=> state.adminCampaignState.inviteEmailSubject);
    let acceptSubject = useSelector(state=> state.adminCampaignState.acceptEmailSubject);
    let inviteBody = useSelector(state=> state.adminCampaignState.inviteEmailBody);
    let acceptBody = useSelector(state=> state.adminCampaignState.acceptEmailBody);

	useEffect(() => {
		(async () => {
			let response = await getCampaignDetailsById(params.id);
			if(response.status === 200){
				setCampaignDetails(response.data);
				dispatch(adminCampaignStateActions.setCampaignDetails(response.data));
			}
		})();
	},[])

	const onFileChange = event => {
        setSelectedFile(event.target.files);
    };

	const pushContentCall = async()=>{
		const formData = new FormData();
		formData.append(
			"content1",
			selectedFile[0]
		);
		formData.append(
			"content2",
			selectedFile[1]
		);
		formData.append("caption",caption);
		console.log(params)
		formData.append("campaignId",params.id);
		let response  = await pushContent(formData);
		showPushContentModal(false)
	}


	const pushStoryCall = async()=>{
		let data = {
			url : storyUrl,
			campaignId: params.id
		}
		console.log(params);
		let response  = await pushStory(data);
		showPushStoryModal(false);
	}
	const itemTemplate = (product) => {
		console.log(product)
		return(
			<div style={{display:"flex",justifyContent:"center"}}>
				{/* Change CDN when going to prod */}
				<img src={`https://cdn-np.glance.com/marketing-tech/inst-campaign-mgmt/${product.contentPath}`} width="300"></img>
			</div>
		)
	}

	return (
		<div className='p-8'>
			 <Toast ref={toast}></Toast>
			<div>
			<Button label='Invite Influencers' onClick={() => showInfluencerList(true)} ></Button>
			<Button className='ml-4' label='Moderate Content' onClick={() => navigate(location.pathname+'/content')} ></Button>
			<Button className='ml-4' label='Invoices' onClick={() => navigate(location.pathname+'/invoices')} ></Button>
			{/* <Button className='ml-4' label='Push Content' onClick={() => showPushContentModal(true)} ></Button> */}
			{/* <Button className='ml-4' label='Push Story' onClick={() => showPushStoryModal(true)} ></Button> */}
			<Button className='ml-4' label='Set Emails' onClick={()=> {showCampaignEmails(true)}}></Button>
			<Button className='ml-4' label='Close Campaign' onClick={async()=> {
				await closeCampaign(params.id);
				await toast.current.show({ severity: 'success', summary: 'Campaign Closed !!', life: 3000 });
			}}></Button>
			</div>
			<div>
				<h2>Campaign Name : {campaignDetails.name}</h2>
				<p>Description :  {campaignDetails.description}</p>
				<p>Status : {campaignDetails.status}</p>
				
			</div>
			<Dialog visible={influencerList} onHide={() => showInfluencerList(false)}>
				<InviteInfluencers  id={params.id}></InviteInfluencers>
			</Dialog>
			<DataTable value={campaignDetails.invitedInfluencersList} header="Invited Influencers" paginator rows={5} first={invitedTablePage} onPage={(e) => setInvitedTablePage(e.first)}>
				<Column field='userName' header='User Name'></Column>
				<Column field='followers' header='Followers'></Column>
				<Column field='email' header='Email'></Column>
 			</DataTable>
			<Button label='Bulk Invitation Reminder' className='mt-4 ml-2' onClick={async()=>{
				if(inviteSubject.length === 0 || inviteBody.length ==0){
					await toast.current.show({ severity: 'error', summary: 'Please set Email Content !!', life: 3000 });
					return;
				}
				let response = await campaignInvitationReminder(params.id,inviteSubject,inviteBody);
				if(response.status !== 200){
					await toast.current.show({ severity: 'error', summary: 'Something went wrong !!', life: 3000 });
				} else{
					await toast.current.show({ severity: 'success', summary: 'Bulk Email sent!!', life: 3000 });
				}
			}}></Button>
			<DataTable value={campaignDetails.acceptedInfluencerList} header="Accepted Influencers" className='mt-8' paginator rows={5} first={acceptedTablePage} onPage={(e) => setAcceptedTablePage(e.first)}>
				<Column field='userName' header='User Name'></Column>
				<Column field='followers' header='Followers'></Column>
				<Column field='email' header='Email'></Column>
				<Column body={(rowData)=>{
					return <Button label='Reminder' onClick={async()=>{
						if(acceptSubject.length === 0 || acceptBody.length ==0){
							await toast.current.show({ severity: 'error', summary: 'Please set Email Content !!', life: 3000 });
							return;
						}
						let response = await campaignAcceptedReminder(rowData.id,params.id,acceptSubject,acceptBody);
						if(response.status !== 200){
							await toast.current.show({ severity: 'error', summary: 'Something went wrong !!', life: 3000 });
						} else{
							await toast.current.show({ severity: 'success', summary: 'Email sent!!', life: 3000 });
						}
					}}></Button>
				}} ></Column>
			</DataTable>
			<Dialog visible={pushContentModal} onHide={() => showPushContentModal(false)} header="Push Content">
				<input type="file" name="contentFiles"  onChange={onFileChange} accept="image/jpeg"  multiple></input>
				<InputTextarea value={caption} placeholder="Enter Caption" onChange={(e) => setCaption(e.target.value)}></InputTextarea><br></br>
				<Button className='p-button-danger' label='Push' onClick={async () => pushContentCall()}></Button>

				<h4>Current</h4>
				<Carousel value={campaignDetails.campaignContent} itemTemplate={itemTemplate} numVisible={1} ></Carousel>
			</Dialog>
{/* 
			<Dialog visible={pushStoryModal} onHide={() => showPushStoryModal(false)} header="Push Story">
				<InputTextarea value={storyUrl} placeholder="Enter Url" onChange={(e) => setStoryUrl(e.target.value)}></InputTextarea><br></br>
				<Button className='p-button-danger' label='Push' onClick={async () => pushStoryCall()}></Button>
			</Dialog> */}

			<Dialog visible={campaignEmail} onHide={() => showCampaignEmails(false)} header="Set Campaign Emails" style={{width:"80%"}}>
				<CampaignEmail></CampaignEmail>	
			</Dialog>

			

		</div>
	)
}
