import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useRef, useState } from 'react';
import { forgotPass } from './auth.http';
import { Toast } from 'primereact/toast';
import './login.scss'

export const ForgotPasswordPage = () => {
    const [email, SetEmail] = useState("");

    const toast = useRef(null);
    const forgotPassCall = async(email)=>{
        let response = await forgotPass(email);
        if(response.status === 200){
            await toast.current.show({ severity: 'success', summary: 'Email Sent', life: 3000 });
        } else {
            await toast.current.show({ severity: 'error', summary: 'Invalid Email', life: 3000 });
        }
    }
    return (
        <div className='forgot-password-page'>
             <Toast ref={toast} />
            <div>
                <InputText placeholder='Enter Email Address' className='field-input' value={email} onChange={(e) => SetEmail(e.target.value)}></InputText>
                <Button label='Reset password' className='field-input p-button-success' 
                onClick={async() => {
                    await forgotPassCall(email);
                }}
                disabled={email.length === 0 }
                ></Button > <br></br><br></br>
                <span>A reset password link will be sent to <br></br> the registered email address</span>
            </div>
        </div>
    )
}
