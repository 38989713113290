import { axiosAuth } from '../../axiosAuth';

let data = require('../../config.js');
let url = data.url;

export const fetchAllInfluencers = async(filters)=>{
    try {
        let response = await axiosAuth.post( '/rest/api/v1/influencer',filters);
        return response;
    } catch (error) {
        return error.response;
    }
}

export const influencerOnboarding = async(data) =>{
    try {
        let response = await axiosAuth.post( '/rest/api/v1/influencer/onboard/' + data.id,data);
        return response;
    } catch (error) {
        return error.response;
    }
}

export const influencerRegister = async(data) =>{
    try {
        let response = await axiosAuth.post( '/rest/api/v1/influencer/onboard/register',data);
        return response;
    } catch (error) {
        return error.response;
    }
}

export const addCampaign = async(data) =>{
    try {
        let response = await axiosAuth.post( '/rest/api/v1/campaign',data);
        return response;
    } catch (error) {
        return error.response;
    }
}

export const getCampaigns = async() =>{
    try {
        let response = await axiosAuth.get( '/rest/api/v1/campaign/all');
        return response;
    } catch (error) {
        return error.response;
    }
}

export const sendMail = async(emailList,subject)=>{
    try {
        let response = await axiosAuth.post( '/rest/api/v1/admin/email/onboarding',{emailList,subject});
        return response;
    } catch (error) {
        return error.response;
    }
}

export const getCampaignDetailsById = async(id) => {
    try {
        let response = await axiosAuth.get( '/rest/api/v1/campaign/' +id);
        return response;
    } catch (error) {
        return error.response;
    }
}

export const sendInviteCampaignEmail = async(userList,id,subject,body) => {
    try {
        let response = await axiosAuth.put( '/rest/api/v1/campaign/invite/' + id,{userList,subject,body});
        return response;
    } catch (error) {
        return error.response;
    }
}

export const downloadFile = async(filename) =>{
    try {
        let response = await axiosAuth.request({
            method : 'GET',
            url :  `/rest/api/v1/campaign/view-content?file=${filename}`
        });
        return response;
    } catch (error) {
        return error.response;
    }
}

export const addFeedback = async(data)=>{
    try {
        let response = await axiosAuth.post( '/rest/api/v1/campaign/add-feedback',data);
        return response;
    } catch (error) {
        return error.response;
    }
}

export const getContentListForCampaign = async(data)=>{
    try {
        let response = await axiosAuth.post( '/rest/api/v1/campaign/fetch-admin',data);
        return response;
    } catch (error) {
        return error.response;
    }
}

export const approvePost = async(data)=>{
    try {
        let response = await axiosAuth.post(  '/rest/api/v1/campaign/post-approve',data);
        return response;
    } catch (error) {
        return error.response;
    }
}

export const fetchAllCategories = async(data)=>{
    try {
        let response = await axiosAuth.get(  '/rest/api/v1/admin/category');
        return response;
    } catch (error) {
        return error.response;
    }
}

export const addCategory = async(name) => {
    try {
        let response = await axiosAuth.post(  '/rest/api/v1/admin/category/' + name);
        return response;
    } catch (error) {
        return error.response;
    }
}

export const deleteCategory = async(id) => {
    try {
        let response = await axiosAuth.delete(  '/rest/api/v1/admin/category/' + id);
        return response;
    } catch (error) {
        return error.response;
    }
}



export const updateInfluencers = async(formData)=>{
    try {
        let response = await axiosAuth.request({
            method : 'POST',
            data : formData,
            url :  '/rest/api/v1/admin/update-influencers',
            headers: { "Content-Type": "multipart/form-data" },
        });
        return response;
    } catch (error) {
        return error.response;
    }
}

export const pushContent = async(formData)=>{
    try {
        let response = await axiosAuth.request({
            method : 'POST',
            data : formData,
            url :  '/rest/api/v1/campaign/push-content',
            headers: { "Content-Type": "multipart/form-data" },
        });
        return response;
    } catch (error) {
        return error.response;
    }
}

export const pushStory = async(data) => {
    try {
        let response = await axiosAuth.post('/rest/api/v1/campaign/push-story',data);
        return response;
    } catch (error) {
        return error.response;
    }
}

export const campaignInvitationReminder = async(campaignId,subject,body) => {
    try {
        let response = await axiosAuth.post('/rest/api/v1/campaign/invite-reminder',{campaignId,subject,body});
        return response;
    } catch (error) {
        return error.response;
    }
}
export const campaignAcceptedReminder = async(influencerId,campaignId,subject,body) => {
    try {
        let response = await axiosAuth.post('/rest/api/v1/campaign/accept-reminder',{influencerId,campaignId,subject,body});
        return response;
    } catch (error) {
        return error.response;
    }
}
export const previewEmail = async(subject,body) => {
    try {
        let response = await axiosAuth.post('/rest/api/v1/campaign/preview-email',{subject,body});
        return response;
    } catch (error) {
        return error.response;
    }
}

export const closeCampaign = async(campaignId) => {
    try {
        let response = await axiosAuth.post('/rest/api/v1/campaign/close-campaign',{campaignId});
        return response;
    } catch (error) {
        return error.response;
    }
}

export const createReferral = async(data) => {
    try {
        let response = await axiosAuth.post('/rest/api/v1/referrals/create',data);
        return response;
    } catch (error) {
        return error.response;
    }
}

export const getReferralDetailsForUser = async(influencerId) => {
    try {
        let response = await axiosAuth.get('/rest/api/v1/referrals/referral-details/'+influencerId);
        return response;
    } catch (error) {
        return error.response;
    }
}
