import { DataTable } from 'primereact/datatable';
import React, { useEffect, useRef, useState } from 'react'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { acceptCampaignInvite, getCampaingDetailsForUser, getInfluencerDetails } from './influencer.http';
import { TabView, TabPanel } from 'primereact/tabview';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { downloadFile } from '../admin-dashboard/admin.http';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useDispatch, useSelector } from 'react-redux';

export const Influencer = () => {

    const params = useParams();
    const [campaignDetails, setCampaignDetails] = useState({});
    const [activeIndex, setActiveIndex] = useState(0);
    const [influencerDetails, setInflucerDetails] = useState({});
    const [postModal, showPostModal] = useState(false);
    const [postLink, setPostLink] = useState('');
    const [termsDialog, showTermsDialog] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState({});
    const [terms,setTerms] = useState('');
    const [numPages, setNumPages] = useState(null);
    const [pdf, setPdf] = useState('');
    const [pageNumber, setPageNumber] = useState(1);

    const navigate = useNavigate();
    const location = useLocation();

    const dispatch = useDispatch();

    const userType = useSelector(state=> state.authState.userType);

    useEffect(() => {
        (async () => {
            let campaign = await getCampaingDetailsForUser(params.id);
            if(campaign.status !== 200){
                localStorage.removeItem('authToken');
                navigate('/login');
            }
            let campaign_data =campaign.data;
            let i=0;
            campaign_data.acceptedCampaignList = campaign_data.acceptedCampaignList.map(item=> {
                item['index'] = i+1;
                i++;
                return item;
            })
            i=0;
            campaign_data.invitedCampaignList = campaign_data.invitedCampaignList.map(item=> {
                item['index'] = i+1;
                i++
                return item;
            })
            setCampaignDetails(campaign.data);

            let influencer = await getInfluencerDetails(params.id);
            setInflucerDetails(influencer.data);
        })();
    }, [])
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }
    return (
        <div className='dashboard-margin '  style={{minHeight: "40rem"}}>
            <h3>Hi {influencerDetails.name} !! Your journey with Glance starts from here! Check out the invited campaigns from us & accept the ones you like &#128516; </h3>

            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header={`Invited Campaigns(${campaignDetails.invitedCampaignList?.length})`}>
                    <DataTable value={campaignDetails.invitedCampaignList}>
                        <Column header="Campaign" field='index'></Column>
                        <Column field='name' header='Name'></Column>
                        <Column field='description' header='Description'></Column>
                        <Column  header='Deliverables' body={(rowData)=>{
                            return <Button label='View' className='p-button-rounded p-button-danger' onClick={ async()=> {
                                showTermsDialog(true);
                                setSelectedCampaign(rowData);
                                if(rowData.deliverableType === 'HTML'){
                                    var temp = document.createElement('div');
                                    temp.innerHTML = rowData.deliverables;
                                    var htmlObject = temp.firstChild;
                                    setTerms(htmlObject);
                                } else {
                                    let response = await downloadFile(rowData.deliverables);
                                    let file = await fetch(`data:application/pdf;base64,${response.data}`)
                                    file = await file.blob();
                                    file = new File([file], 'deliverables.pdf',{type : 'application/pdf'});
                                    setPdf(await file.arrayBuffer());
                                }
                            }}></Button>
                        }}></Column>
                        <Column body={(rowData) => {
                            return <Button label='Accept' className='p-button-danger start-creating' onClick={async () => {
                                let response = await acceptCampaignInvite(rowData.id, influencerDetails)
                                let campaign = await getCampaingDetailsForUser(params.id);
                                setCampaignDetails(campaign.data);
                            }}></Button>
                        }}></Column>
                    </DataTable>
                </TabPanel>
                <TabPanel header={`Accepted Campaigns(${campaignDetails.acceptedCampaignList?.length})`}>
                    <DataTable value={campaignDetails.acceptedCampaignList}>
                        <Column header="Campaign" field='index'></Column>
                        <Column field='name' header='Name'></Column>
                        <Column field='description' header='Description'></Column>
                        <Column  header='Deliverables' body={(rowData)=>{
                            return <Button label='View' className='p-button-rounded p-button-danger' onClick={ async()=> {
                                showTermsDialog(true);
                                setSelectedCampaign(rowData);
                                if(rowData.deliverableType === 'HTML'){
                                    var temp = document.createElement('div');
                                    temp.innerHTML = rowData.deliverables;
                                    var htmlObject = temp.firstChild;
                                    setTerms(htmlObject);
                                } else {
                                    let response = await downloadFile(rowData.deliverables);
                                    let file = await fetch(`data:application/pdf;base64,${response.data}`)
                                    file = await file.blob();
                                    file = new File([file], 'deliverables.pdf',{type : 'application/pdf'});
                                    setPdf(await file.arrayBuffer());
                                }
                            }}></Button>
                        }}></Column>
                        <Column body={(rowData) => {
                            return <Button label='Start Creating ' className='p-button-danger start-creating' onClick={async () => {
                                navigate(location.pathname + '/campaign/'+rowData.campaign_id);
                            }}></Button>
                        }}></Column>
                    </DataTable>
                </TabPanel>
            </TabView>

            <Dialog visible={termsDialog} onHide={() => showTermsDialog(false)} style={{width : "70%"}} >
                {selectedCampaign.deliverableType === 'HTML' ? <div dangerouslySetInnerHTML={{__html: selectedCampaign.deliverables}} /> : 
                <div style={{ width: "100%" }}>
                        <header className="App-header" >
                            <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
                                {Array.from(new Array(numPages), (el, index) => (
                                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                                ))}
                            </Document>

                        </header>
                        <Button label='Download' className='m-4' onClick={() => {
                            const file = new Blob([pdf], { type: 'application/pdf' });
                            const fileURL = URL.createObjectURL(file);
                            const link = document.createElement("a");
                            link.href = fileURL;
                            link.download = "terms-and-condition.pdf";
                            link.click();
                        }}  ></Button>
                </div>}
			</Dialog>
           
        </div>
    )
}
