import React, { Fragment, useEffect, useRef, useState } from 'react'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { InputText } from 'primereact/inputtext';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';
import { fetchAllInfluencers, sendInviteCampaignEmail } from './admin.http';
import { useDispatch, useSelector } from 'react-redux';

export const InviteInfluencers = (props) => {

    const [influencers, setInfluencers] = useState([]);
    const [sidebar, showSidebar] = useState(false);
    const [username, setUsername] = useState('');
    const [minFollowers, setMinFollower] = useState('');
    const [maxFollowers, setMaxFollower] = useState('');
    const [onboarded, setOnboarded] = useState(false);
    const toast = useRef(null);

    const dispatch  = useDispatch();
    const campaignDetails = useSelector( state => state.adminCampaignState.campaignDetails);

    let inviteSubject = useSelector(state=> state.adminCampaignState.inviteEmailSubject);
    let acceptSubject = useSelector(state=> state.adminCampaignState.acceptEmailSubject);
    let inviteBody = useSelector(state=> state.adminCampaignState.inviteEmailBody);
    let acceptBody = useSelector(state=> state.adminCampaignState.inviteEmailBody);

    let fetchInfluencer = async(filters) =>{
        let response = await fetchAllInfluencers(filters);
        if (response.status === 200) {
            let data = response.data;
            let invitedInfluencersList = [];
            invitedInfluencersList = invitedInfluencersList.concat(campaignDetails.acceptedInfluencerList);
            invitedInfluencersList = invitedInfluencersList.map(item => item.email);
            data = data.filter(item => {
                if(!invitedInfluencersList.includes(item.email)){
                    return item;
                }
            })
            setInfluencers(data);
        }
    }

    useEffect(() => {
        (async () => {
            await fetchInfluencer({onBoarded : true })
        })();
    }, []);

    const sendInviteMail = async(data)=>{
        if(inviteSubject.length === 0 || inviteBody.length === 0){
            toast.current.show({severity:'error', summary: 'Please set Email Content', life: 3000});
            return;
        }
        let response = await sendInviteCampaignEmail(data, props.id,inviteSubject,inviteBody);
        if (response.status === 200) {
            toast.current.show({severity:'success', summary: 'Email Sent Successfully', life: 3000});
        }
    }
    return (
        <Fragment>
            <Toast ref={toast} />
            <div className="card m-4 p-4">
                <Button icon="pi pi-arrow-right" label='Filters' onClick={(e) => showSidebar(true)} />
                <h2>Search Resultss : {influencers.length}</h2>
                <DataTable value={influencers} paginator rows={25} filterDisplay="menu">
                    <Column field="userName" header="Username" filter={true} filterMatchMode="contains" ></Column>
                    <Column field="followers" header="Followers" ></Column>
                    <Column field="email" header="Email"></Column>
                    {/* <Column header="Engagement (%)" body={(rowData) => {
                        let engagement = rowData.engagement.slice(0, 4);
                        return engagement;
                    }}></Column> */}
                    <Column field="onBoarded" header="Onboarded" body={(data) => {
                        return JSON.stringify(data.onboarded)
                    }}></Column>
                    <Column header="" body={(rowData) => {
                        return <Button label='Invite' onClick={() => {
                            let user = [];
                            user.push(rowData);
                            sendInviteMail(user);
                        }}></Button>
                    }}></Column>
                </DataTable>
                <Button className='p-button-primary' label='Send Campaign Invite' onClick={() => {
                    sendInviteMail(influencers);
                }} /> 
            </div>

            <Sidebar visible={sidebar} onHide={() => showSidebar(false)} style={{ width: "30rem" }}>
                <div>
                    <h3>Add Filters</h3>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        fetchInfluencer({onBoarded : true, minFollowers , maxFollowers})
                        showSidebar(false)
                    }}>

                        <div className="p-field">
                            <h4>Followers</h4>
                            <div className='flex'>
                                <InputText id="minFollowers" placeholder='Min.' className='p-inputtext p-d-block' value={minFollowers} onChange={(e) => setMinFollower(e.target.value)} />
                                <InputText id="maxFollowers" placeholder='Max.' className='p-inputtext p-d-block ml-2' value={maxFollowers} onChange={(e) => setMaxFollower(e.target.value)} />
                            </div>
                        </div>

                        <Button className='p-button-primary mt-4' label='Apply'></Button>
                    </form>
                </div>
            </Sidebar>

        </Fragment>
    )
}
