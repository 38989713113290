import { Navigate, Outlet, useLocation } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
export const PrivateRoute = () =>{
    const token = localStorage.getItem('authToken');

    const authState = useSelector(state =>state.authState);
    const location = useLocation();
    let url = location.pathname;
    let isAuthenticated = false;
    if(token){
        isAuthenticated = true;
    }

    return isAuthenticated ? <Outlet /> : <Navigate to='/login' />
}