import { Button } from 'primereact/button'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { uploadInvoice } from '../influencer.http';

export const InvoiceUpload = () => {
    const [selectedFile, setSelectedFile] = useState('');
    const onFileChange = event => {
        setSelectedFile(event.target.files[0]);
    };
    const params = useParams();

    const upload = async()=>{
        let formData = new FormData();
        formData.append(
            "file",
            selectedFile,
            selectedFile.name
        );
        formData.append('influencerId',params.id);
        formData.append('campaignId', params.campaignId);
        let response = await uploadInvoice(formData);
        alert('Uploaded');
    }

    return (
        <div style={{minHeight: "60vh", display:"flex", flexDirection:"column",alignItems:"center",margin:"2rem"}}>
            <h1>Please Upload your filled invoice here</h1>
            <input type='file' required onChange={onFileChange}  accept='.pdf' style={{marginLeft:"5.5rem"}}></input>
            <Button label='Upload Invoice' className='p-button-danger' onClick={async()=>{
                await upload();
            }} style={{width: "10rem"}}></Button>
        </div>
    )
}
