import { InputText } from 'primereact/inputtext'
import React from 'react'
import { Editor } from 'primereact/editor';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { adminCampaignStateActions } from '../../store';
import { previewEmail, sendInviteCampaignEmail } from './admin.http';

export const CampaignEmail = () => {

    let inviteSubject = useSelector(state=> state.adminCampaignState.inviteEmailSubject);
    let acceptSubject = useSelector(state=> state.adminCampaignState.acceptEmailSubject);
    let inviteBody = useSelector(state=> state.adminCampaignState.inviteEmailBody);
    let acceptBody = useSelector(state=> state.adminCampaignState.acceptEmailBody);
    const dispatch = useDispatch();

    const invitePreview = async()=>{
        // if(inviteSubject.length === 0 || inviteBody.length === 0){
        //     toast.current.show({severity:'error', summary: 'Please set Email Content', life: 3000});
        //     return;
        // }
        let response = await previewEmail(inviteSubject,inviteBody);
        // if (response.status === 200) {
        //     toast.current.show({severity:'success', summary: 'Preview Sent Successfully', life: 3000});
        // }
    }

    const acceptPreview = async()=>{
        // if(acceptSubject.length === 0 || acceptBody.length === 0){
        //     toast.current.show({severity:'error', summary: 'Please set Email Content', life: 3000});
        //     return;
        // }
        let response = await previewEmail(inviteSubject,inviteBody);
        // if (response.status === 200) {
        //     toast.current.show({severity:'success', summary: 'Preview Sent Successfully', life: 3000});
        // }
    }
    return (
        <div>
            <div className="p-field p-col m-2">
                <label htmlFor="campaign"><b>Invite Email Subject</b></label>
                <InputText
                    id="inviteSubject"
                    name="inviteSubject"
                    type="text"
                    style={{ width: "100%" }}
                    onChange={(e)=>{
                        dispatch(adminCampaignStateActions.setInviteEmailSubject(e.target.value));
                    }}
                    value={inviteSubject}
                />
            </div>
            <div className="p-field p-col m-2">
                <label htmlFor="campaign"><b>Invite Email Body</b></label>
                <Editor style={{ height: '320px' }}
                    id="inviteBody"
                    name="inviteBody"
                    onTextChange={(e)=>{
                        dispatch(adminCampaignStateActions.setInviteEmailBody(e.htmlValue));
                    }}a
                    value={inviteBody}
                />
            </div>

            <Button label='Send Preview Email' className='ml-2' onClick={async()=>{
                await invitePreview();
            }}></Button>
            <div className="p-field p-col m-2">
                <label htmlFor="campaign"><b>Accpeted Reminder Email Subject</b></label>
                <InputText
                    id="acceptSubject"
                    name="acceptSubject"
                    type="text"
                    style={{ width: "100%" }}
                    onChange={(e)=>{
                        dispatch(adminCampaignStateActions.setAcceptEmailSubject(e.target.value));
                    }}
                    value={acceptSubject}
                />
            </div>
            <div className="p-field p-col m-2">
                <label htmlFor="campaign"><b>Accept Reminder Email Body</b></label>
                <Editor style={{ height: '320px' }}
                    id="acceptBody"
                    name="acceptBody"
                    onTextChange={(e)=>{
                        dispatch(adminCampaignStateActions.setAcceptEmailBody(e.htmlValue));
                    }}
                    value={acceptBody}
                />
            </div>
            <Button label='Send Preview Email' className='ml-2' onClick={async()=>{
                await acceptPreview();
            }}></Button>
        </div>
    )
}
