import React from 'react';
import './footer.scss'

export const Footer = () => {
    return (

        <footer className='footer'>
            <section>
                <div className="max1170 footer-container">
                    <div className="footer-menu grid">
                        <div className="single-col">
                            <h4 className="row-heading">Company</h4>
                            <ul>

                                <li><a href="https://www.glance.com/newsroom" target="_blank" rel="noreferrer" className="ga-event" data-event-action="Click - News Link" data-event-category="Footer Menu" data-event-label="Newsroom">Newsroom</a>
                                </li>

                                <li><a href="https://www.glance.com/careers" target="_blank" rel="noreferrer" className="ga-event" data-event-action="Click - News Link" data-event-category="Footer Menu" data-event-label="Careers">Careers</a>
                                </li>

                                <li><a href="https://engg.glance.com/" target="_blank" rel="noreferrer" className="ga-event" data-event-action="Click - News Link" data-event-category="Footer Menu" data-event-label="Blog">Blog</a>
                                </li>

                            </ul>
                        </div>
                        <div className="single-col pl-4">
                            <h4 className="row-heading">Legal</h4>
                            <ul>

                                <li><a href="https://www.glance.com/eula"  target="_blank" rel="noreferrer" className="ga-event" data-event-category="Footer Menu" data-event-action="Click - EULA Link" data-event-label="EULA">EULA</a></li>

                                <li><a href="https://www.glance.com/privacy-policy" target="_blank" rel="noreferrer" className="ga-event" data-event-category="Footer Menu" data-event-action="Click - Privacy Policy Link" data-event-label="Privacy Policy">Privacy Policy</a></li>

                                <li><a href="https://www.glance.com/security" target="_blank" rel="noreferrer" className="ga-event" data-event-category="Footer Menu" data-event-action="Click - Security Link" data-event-label="Security">Security</a></li>

                                <li><a href="https://www.glance.com/compliance" target="_blank" rel="noreferrer" className="ga-event" data-event-category="Footer Menu" data-event-action="Click - Compliance Link" data-event-label="Compliance">Compliance</a></li>

                                <li><a href="https://www.glance.com/advertiser-terms" target="_blank" rel="noreferrer" className="ga-event" data-event-category="Footer Menu" data-event-action="Click - Advertiser Terms Link" data-event-label="Advertiser Terms">Advertiser Terms</a></li>

                                <li><a href="https://www.glance.com/marketing-services-terms"  target="_blank" rel="noreferrer" className="ga-event" data-event-category="Footer Menu" data-event-action="Click - Marketing Service Terms Link" data-event-label="Marketing Service Terms">Marketing Service Terms</a></li>

                            </ul>
                        </div>
                    </div>
                    <div className="footer-social">
                        <div className="social-media">
                            <ul>

                                <li>
                                    <a className="ga-event" data-event-action="Click - Facebook Link" data-event-category="Footer Menu" data-event-label="Facebook Social Icon" href="https://www.facebook.com/glancescreen/">
                                        <img src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/facebook-icon.png" />
                                    </a>
                                </li>

                                <li>
                                    <a className="ga-event" data-event-action="Click - Twitter Link" data-event-category="Footer Menu" data-event-label="Twitter Social Icon" href="https://www.twitter.com/glancescreen">
                                        <img src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/twitter-icon.png" />
                                    </a>
                                </li>

                                <li>
                                    <a className="ga-event" data-event-action="Click - Instagram Link" data-event-category="Footer Menu" data-event-label="Instagram Social Icon" href="https://www.instagram.com/glancescreen/">
                                        <img src="https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/instagram-icon.png" />
                                    </a>
                                </li>

                            </ul>
                        </div>
                        <div className="copyright">© All rights reserved</div>
                    </div>
                </div>
            </section>
        </footer>
    )
}
