import logo from './logo.svg';
import './App.css';
import React, { useEffect, useMemo } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes
} from "react-router-dom";
import { Admin } from './components/admin-dashboard';
import { SearchHashtag } from './components/admin-dashboard/search-influencers';
import { Navbar } from './components/admin-dashboard/Navbar';
import { Influencers } from './components/admin-dashboard/Influencers';
import { OnboardingForm } from './components/influencer-dashboard/OnboardingForm';
import { Campaign } from './components/admin-dashboard/Campaign';
import { CampaignDetails } from './components/admin-dashboard/CampaignDetails';
import { Login } from './components/auth/Login';
import { Influencer } from './components/influencer-dashboard/Influencer';
import { useDispatch, useSelector } from 'react-redux';
import { me } from './components/auth/auth.http';
import { authStateActions } from './store';
import { PrivateRoute } from './components/auth/PrivateRoute';
import { InfluencerCampaignDetails } from './components/influencer-dashboard/InfluencerCampaginDetails';
import { CampaignContent } from './components/admin-dashboard/CampaignContent';
import { NavbarInfluencer } from './components/influencer-dashboard/Navbar';
import { Footer } from './components/footer/Footer';
import { Category } from './components/admin-dashboard/Category';
import { AdminLogin } from './components/auth/AdminLogin';
import { ForgotPassword } from './components/influencer-dashboard/ForgotPassword';
import { ForgotPasswordPage } from './components/auth/ForgotPasswordPage';
import { Invoices } from './components/admin-dashboard/invoice-component/Invoices';
import { InvoiceUpload } from './components/influencer-dashboard/invoice-upload/InvoiceUpload';
import { Referral } from './components/admin-dashboard/referral-page/Referral';
function App() {

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.authState.isAuthenticated);
  let userType = useSelector(state=> state?.authState?.userType);
  useMemo(() => {
    (async () => {
      let userId = localStorage.getItem('userId');
      if (userId) {
        let response = await me(userId, localStorage.getItem('userType'));
        if (response.status === 200) {
          dispatch(authStateActions.setAuthState({
            isAuthenticated: true,
            authToken: localStorage.getItem('authToken'),
            userId: localStorage.getItem('userId')
          }))
        } else {
          dispatch(
            authStateActions.setAuthState({
              isAuthenticated: false,
              authToken: null,
              userId: null
            }))
          localStorage.removeItem('authToken');
          localStorage.removeItem('userId')
        }
      }
    })();
  })
  



  return (
    // <div style={{ backgroundImage: `url('https://web.glance-cdn.com/home_stories/images/desk_uploads/RC_full.png')`,height:"100vh", backgroundRepeat : "no-repeat",backgroundSize: "cover"}}>
    <div>
      <div>
        <Router>

          {(userType === 'ADMIN') && isAuthenticated ? <Navbar></Navbar> : (userType === 'INFLUENCER')  && isAuthenticated ? <div><NavbarInfluencer></NavbarInfluencer></div> : <div></div>}
          <Routes>
            <Route exact path="/" element={<Login />}></Route>
            <Route exact path="/login" element={<Login />}></Route>
            <Route exact path="/admin/login" element={<AdminLogin />}></Route>
            <Route path='/influencer/onboard' element={<OnboardingForm />}></Route>
            <Route path='/influencer/onboard/:id' element={<OnboardingForm />}></Route>
            <Route path='/forgot-password' element={<ForgotPasswordPage />}></Route>
            <Route path='/influencer/:influencerId/reset-password/:forgotPasswordId' element={<ForgotPassword />}></Route>


            <Route exact path='/admin' element={<PrivateRoute />}>

              <Route path='/admin' element={<Admin />}>
              </Route>

            </Route>
            <Route exact path='/admin/category' element={<PrivateRoute />}>
              <Route exact path='/admin/category' element={<Category />}></Route>
            </Route>
            <Route exact path='/admin/influencers' element={<PrivateRoute />}>
              <Route exact path='/admin/influencers' element={<Influencers />}></Route>
            </Route>
            <Route exact path='/admin/campaigns' element={<PrivateRoute />}>
              <Route exact path='/admin/campaigns' element={<Campaign />}></Route>
            </Route>
            <Route exact path='/admin/campaign/:id' element={<PrivateRoute />}>
              <Route exact path='/admin/campaign/:id' element={<CampaignDetails />}></Route>
            </Route>
            <Route exact path='/admin/campaign/:id/content' element={<PrivateRoute />}>
              <Route exact path='/admin/campaign/:id/content' element={<CampaignContent />}></Route>
            </Route>
            <Route exact path='/admin/campaign/:id/invoices' element={<PrivateRoute />}>
              <Route exact path='/admin/campaign/:id/invoices' element={<Invoices />}></Route>
            </Route>
            <Route exact path='/admin/referral/' element={<PrivateRoute />}>
              <Route exact path='/admin/referral/' element={<Referral />}></Route>
            </Route>
            <Route exact path='/influencer/:id' element={<PrivateRoute />}>
              <Route exact path='/influencer/:id' element={<Influencer />}></Route>
            </Route>
            <Route exact path='/influencer/:id/campaign/:campaignId' element={<PrivateRoute />}>
              <Route exact path='/influencer/:id/campaign/:campaignId' element={<InfluencerCampaignDetails />}></Route>
            </Route>

            <Route exact path='/influencer/:id/invoice/:campaignId' element={<PrivateRoute />}>
              <Route exact path='/influencer/:id/invoice/:campaignId' element={<InvoiceUpload />}></Route>
            </Route>


          </Routes>

        </Router>
      </div>
      <Footer />

    </div>
  );
}

export default App;
