import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import { downloadFile, getCampaignDetailsById } from '../admin-dashboard/admin.http';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { fetchCampaignContentDetails, postContent, postStoryDetails, updateContentDetails, uploadPostAnalytics, uploadPostLink } from './influencer.http';
import { InputTextarea } from 'primereact/inputtextarea';
import { current } from '@reduxjs/toolkit';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { Buffer } from 'buffer';
import { useDispatch, useSelector } from 'react-redux';
import { authStateActions } from '../../store';
import { Toast } from 'primereact/toast';

import background from "../auth/background.jpg";
import { Carousel } from 'primereact/carousel';

export const InfluencerCampaignDetails = () => {
	const [campaign, setCampaign] = useState({});
	const [contentList, setContentList] = useState([]);
	const [storyContent, setStoryContent] = useState([]);
	const param = useParams();
	const [postModal, showPostModal] = useState(false);
	const [caption, setCaption] = useState('');
	const [selectedFile, setSelectedFile] = useState('');
	const [selectedFileBuffer, setSelectedFileBuffer] = useState('');
	const [storyLikes, setStoryLikes] = useState('');
	const [storyViews, setStoryViews] = useState('');
	const [storyDetailsModal, showStoryDetailsModal] = useState(false);
	const [storyShot, setStoryShot] = useState('');
	const [storyFileBuffer, setStoryFileBuffer] = useState('');

	const [currentFile, setCurrentFile] = useState('');
	const [currentFileName, setCurrentFileName] = useState('')
	const [showFeedbackModal, setShowFeedbackModal] = useState(false);
	const [feedback, setFeedback] = useState('');
	const [postId, setPostId] = useState('');
	const [postLink, setPostLink] = useState('');
	const [postLinkModal, showPostLinkModal] = useState(false);
	const [insightsModal, showInsightsModal] = useState(false);
	const [pushContent, setPushContent] = useState([])

	const toast = useRef(null);

	const fetchContent = async () => {
		let response = await fetchCampaignContentDetails({ influencerId: param.id, campaignId: param.campaignId });
		// setPushContent(response.data.campaignContent)
		// let data = response.data.contentList;
		// await contentHash(data);
		// setContentList(data)
		setStoryContent([response.data.campaignContent]);
		setCampaign(response.data);

	}
	const itemTemplate = (product) => {
		console.log(product)
		return (
			<div style={{ display: "flex", justifyContent: "center" }}>
				<img src={`https://cdn-np.glance.com/marketing-tech/inst-campaign-mgmt/${product.contentPath}`} width="300"></img>
			</div>
		)
	}

	const contentHash = async (data) => {
		
		for (const item of data) {
			let responseHash = await downloadFile(item.filename);
			item['hash'] = responseHash.data;
		}
	}

	const formik = useFormik({
		initialValues: {
			likes: '',
			comments: '',
			views: ''
		},
		validate: (data) => {
			let errors = {};
			if (!data.likes) {
				errors.likes = "Likes are required";
			}
			if (!data.comments) {
				errors.comments = "Comments are required";
			}
			if (!data.views) {
				errors.views = "Views are required";
			}
			return errors;
		},
		onSubmit: async (data) => {
			data['postId'] = postId;
			let response = await uploadPostAnalytics(data);
			showInsightsModal(false);
			await fetchContent();

		}
	})

	const userType = useSelector(state => state.authState.userType);
	const dispatch = useDispatch();
	useEffect(() => {
		(async () => {
			await fetchContent();
		})();
	}, []);

	const onFileChange = async (event) => {
		let file = event.target.files[0];
		setSelectedFile(file);
		await setSelectedFileBuffer(await file.arrayBuffer())
	};
	const storyFileChange = async (event) => {
		let file = event.target.files[0];
		setStoryShot(file);
		await setStoryFileBuffer(await file.arrayBuffer())
	};

	const uploadStoryScreenshot = async () => {
		let formData = new FormData();
		formData.append('content', storyShot);
		formData.append('influencerId', param.id);
		formData.append('campaignId', param.campaignId);
		formData.append('caption', caption);
		formData.append('likes', storyLikes);
		formData.append('views', storyViews);
		let response = await postStoryDetails(formData);
		if(response.status === 200){
			await toast.current.show({severity:'success', summary: 'Uploaded Sucessfully !!', life: 3000});
		} else{
			await toast.current.show({severity:'error', summary: 'Something went wrong, please try again!!', life: 3000});
		}
		showStoryDetailsModal(false)
	}
	const isFormFieldValid = (name) =>
		!!(formik.touched[name] && formik.errors[name]);
	const getFormErrorMessage = (name) => {
		return (
			isFormFieldValid(name) && (
				<small className="p-error">{formik.errors[name]}</small>
			)
		);
	}
	return (
		<div className='influencer-campaign' >
			<Toast ref={toast} style={{width : "70%"}}></Toast>
			<div className='campaign-details' >
				<span className='campaign-span'><b>Campaign Name : </b> {campaign.name}</span>
				<span className='campaign-span'><b>Description :</b>  {campaign.description}</span>
				<div style={{ float: "right" }}>
					{/* <Button className='p-button-rounded p-button-danger' label='Upload Content'  onClick={() => {
						showPostModal(true);
					}}></Button> */}
				</div>
			</div>



			{/* <div >
				<h3>Please share the following data on instagram with the caption and add the link for verification!!</h3>
				<Carousel value={pushContent} itemTemplate={itemTemplate} numVisible={1}></Carousel>
				
				<div style={{display: "flex" , justifyContent: "center",flexDirection: "column",width: "30%"}}>
					<h3>Caption to be posted!!</h3>
					<InputTextarea value={pushContent[0]?.caption} readOnly></InputTextarea>
				</div>
			</div> */}
			{
				campaign.status !== 'CLOSED' ? 
				<div className="card pt-4 mb-8">
				<DataTable value={storyContent} header="Please Share the story Content">
					<Column body={(rowData) => {
						return <Button className='p-button-danger p-button-rounded' label='Upload Insights' onClick={async () => {

							showStoryDetailsModal(true)

						}}> </Button>
					}} ></Column>

				</DataTable>
			</div> : <div> This campaign is no longer accepting content</div>
			}


			<div className="card pt-4 mb-8">
				{/* <DataTable value={contentList} >
					<Column header="Preview" body={(rowData) => {
						return <img src={`data:image/png;base64,${rowData.hash}`} alt="" height={200} width={200} />
					}} ></Column>
					<Column field='caption' header="Caption" ></Column>
					<Column field='status' header="Status" ></Column>
					<Column field='link' header="Link" ></Column>
					<Column body={(rowData) => {
						return rowData.status === 'FEEDBACK' ? <Button className='p-button-success p-button-rounded' label='Edit' onClick={async () => {
							let response = await downloadFile(rowData.filename);
							setCurrentFile(response.data);
							setCurrentFileName(rowData.filename);
							setCaption(rowData.caption);
							setShowFeedbackModal(true);
							setPostId(rowData.id)
							setFeedback(rowData.feedback);


						}}></Button> : rowData.status === 'APPROVED' ? <div className='upload' >
							<Button className='p-button-danger p-button-rounded ' label='Upload Post Link' onClick={() => { showPostLinkModal(true); setPostId(rowData.id); }} ></Button>
						</div> :
							rowData.status === 'LIVE' ? <div className='upload'>
								<Button className='p-button-danger p-button-rounded ml-2 ' label='Upload Insights' onClick={() => { showInsightsModal(true); setPostId(rowData.id); }} ></Button>
							</div> : <div></div>
					}} ></Column>

				</DataTable> */}
			</div>



			<Dialog visible={postModal} onHide={() => {
				showPostModal(false);
				setSelectedFile('');
				setSelectedFileBuffer('');
				setCaption('');
			}} style={{ width: "30rem" }}>
				<h2>Upload Content</h2>
				{selectedFile ? <div>
					<img src={`data:image/png;base64,${Buffer.from(selectedFileBuffer).toString("base64")}`} alt="" height={200} width={400} />
				</div> : <div></div>}
				<div className='field'>
					<input type='file' placeholder='Choose file' onChange={onFileChange} required accept='.jpeg'></input>
				</div>

				<div className='field'>
					<InputTextarea placeholder='Caption' value={caption} onChange={(e) => setCaption(e.target.value)}></InputTextarea>
				</div>
				<div className='field'>

					<Button label='Post' className='p-button-danger' onClick={async () => {
						let formData = new FormData();
						formData.append('content', selectedFile, `${param.id}-${param.campaignId}-${selectedFile.name}`);
						formData.append('influencerId', param.id);
						formData.append('campaignId', param.campaignId);
						formData.append('caption', caption);
						let response = await postContent(formData);
						showPostModal(false);
						await fetchContent();
					}} ></Button>
				</div>
			</Dialog>


			<Dialog visible={showFeedbackModal} onHide={() => setShowFeedbackModal(false)} >
				<div className=''>
					<h3>Content Posted</h3>
					<img src={`data:image/png;base64,${currentFile}`} alt="" height={200} width={400} />

					<div className='field'>
						<input type='file' placeholder='Reupload content' onChange={onFileChange}></input>
					</div>
					<h4>Caption : </h4>
					<InputTextarea value={caption} onChange={(e) => setCaption(e.target.value)}></InputTextarea>
				</div>
				<div className='mt-2'>
					<h3>Feedback</h3>
					<InputTextarea value={feedback} disabled={true} ></InputTextarea>
					<Button className='p-button-danger p-button-rounded ml-2' label='Post' onClick={async () => {

						let formData = new FormData();

						if (!selectedFile) {
							let file = await fetch(`data:image/png;base64,${currentFile}`);
							file = await file.blob();
							file = new File([file], currentFileName, { type: 'image/png' });
							formData.append('content', file, currentFileName);
						} else {
							formData.append('content', selectedFile, `${param.id}-${param.campaignId}-${selectedFile.name}`);
						}
						formData.append('caption', caption);
						formData.append('postId', postId);

						await updateContentDetails(formData);
						setShowFeedbackModal(false);
						await fetchContent();
					}}></Button>
				</div>


			</Dialog>

			<Dialog visible={postLinkModal} onHide={() => showPostLinkModal(false)} className="modal-width" >
				<div className='' style={{ display: "flex", flexDirection: "column" }}>
					<h3>Add Link</h3>
					<h4>*Kindly share the link of your post that you have uploaded on Instagram</h4>
					<InputTextarea value={postLink} onChange={(e) => setPostLink(e.target.value)}></InputTextarea>

					<br></br>
					<br></br>
					<Button className='p-button-danger' label='Upload Link' onClick={async () => {
						let response = await uploadPostLink({ postId, link: postLink });
						setPostLink('');
						showPostLinkModal(false);

					}}></Button>
				</div>
			</Dialog>

			<Dialog visible={insightsModal} onHide={() => showInsightsModal(false)} className="modal-width" >
				<div className=''>
					<h3>Insights</h3>
					<form className='p-fluid' onSubmit={formik.handleSubmit}>
						<div className='field' >
							<InputText name="likes" type="likes" autoFocus placeholder='Enter likes' value={formik.values.likes} onChange={formik.handleChange} className={classNames({
								"p-invalid": isFormFieldValid("likes"),
							})} />

							{getFormErrorMessage("likes")}
						</div>
						<div className='field'>
							<InputText name="comments" placeholder='Enter comments' value={formik.values.comments} onChange={formik.handleChange} className={classNames({
								"p-invalid": isFormFieldValid("comments"),
							})} />
							{getFormErrorMessage("comments")}
						</div>
						<div className='field'>
							<InputText name="views" placeholder='Enter views' value={formik.values.views} onChange={formik.handleChange} className={classNames({
								"p-invalid": isFormFieldValid("views"),
							})} />
							{getFormErrorMessage("views")}
						</div>
						<div className='field' style={{ width: "40%" }}>
							<Button label='Add' type='submit'></Button>
						</div>
					</form>
				</div>
			</Dialog>


			<Dialog visible={storyDetailsModal} onHide={() => showStoryDetailsModal(false)} className="modal-width" >
				<div className='' style={{ display: "flex", flexDirection: "column" }}>
					<h4>*Kindly share the the screenshot of the story with number of views and likes with 24 hrs of posting story.</h4>
					<div className='field'>
						<input type='file' placeholder='Choose file' onChange={storyFileChange} required accept='.jpeg,.png'></input>
					</div>
					<div className='field'  >
						<InputText placeholder='Enter number of views' value={storyViews} onChange={(e) => setStoryViews(e.target.value)}></InputText>
					</div>
					<div className='field'>
						<InputText placeholder='Enter number of likes' value={storyLikes} onChange={(e) => setStoryLikes(e.target.value)}></InputText>
					</div>
					<div className='field'>
						<Button label='Upload' className='p-button-danger' onClick={async () =>  uploadStoryScreenshot() }></Button>
					</div>

				</div>
			</Dialog>

		</div>
	)
}
