import React, { Fragment, useEffect, useRef, useState } from 'react'
import { fetchAllInfluencers, sendInviteCampaignEmail, sendMail, updateInfluencers } from './admin.http';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { InputText } from 'primereact/inputtext';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
 

export const Influencers = (props) => {

    const [influencers, setInfluencers] = useState([]);
    const [sidebar, showSidebar] = useState(false);
    const [username, setUsername] = useState('');
    const [minFollowers, setMinFollower] = useState('');
    const [maxFollowers, setMaxFollower] = useState('');
    const [emailSubjectFilter,setEmailSubjectFilter] = useState('');
    const [onboarded, setOnboarded] = useState(false);
    const [invitationSent, setInvitationSent] = useState(false);
    const [invitationNotSent, setInvitationNotSent] = useState(true);
    const [fileUploadModal, showFileUploadModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState('');
    const [mailModal,showMailModal] = useState(false);
    const [mailSubject,setMailSubject] = useState('');
    const [emailList,setEmailList] = useState([]);
    const [userLimit,setUserLimit] = useState('');

    const mode = props.mode;
    const toast = useRef(null);

    const onFileChange = async (event) => {
		let file = event.target.files[0];
		setSelectedFile(file);
	};
    useEffect(() => {
        (async () => {
            let response = await fetchAllInfluencers({ onBoarded: false,invitationNotSent:true });
            if (response.status === 200) {
                setInfluencers(response.data);
            }
        })();
    }, []);

    const searchByFilters = async () => {
        let filters = {
            username,
            minFollowers,
            maxFollowers,
            onBoarded: onboarded,
            invitationSent ,
            emailSubject: emailSubjectFilter,
            invitationNotSent,
            userLimit
        }
        let response = await fetchAllInfluencers(filters);
        if (response.status === 200) {
            setInfluencers(response.data);
        }
        showSidebar(false);


    }

    const sendMailCall = async () => {
        let users = influencers.map(item => item.id);
        console.log(users);
        if(mailSubject.length === 0){
            toast.current.show({severity:'error', summary: 'Please Set the email Subject', life: 3000});
            return;
        }
        let response = await sendMail(users,mailSubject);
        if (response.status === 200) {
            toast.current.show({severity:'success', summary: 'Email Sent Successfully', life: 3000});
        }
    }

    const sendIndividualEmail = async (user) => {
        user = user.map(item => item.id);
        console.log(user);
        console.log(mailSubject);
        if(mailSubject.length === 0){
            toast.current.show({severity:'error', summary: 'Please Set the email Subject', life: 3000});
            return;
        }
        let response = await sendMail(user,mailSubject);
        if (response.status === 200) {
            toast.current.show({severity:'success', summary: 'Email Sent Successfully', life: 3000});
        }
    }

    const sendInviteMail = async () => {
        let response = await sendInviteCampaignEmail(influencers, props.id);
        if (response.status === 200) {
            toast.current.show({severity:'success', summary: 'Email Sent Successfully', life: 3000});
        }
    }
    return (
        <Fragment>
            <Toast ref={toast} />
            <div className="card m-4 p-4">
                <Button icon="pi pi-arrow-right" label='Filters' onClick={(e) => showSidebar(true)} />
                <Button icon="pi pi-plus" className='p-button-danger ml-4' label='Update Influencers'  onClick={()=> showFileUploadModal(true)} />
               
                <h2>Search Results : {influencers.length}</h2>
                <DataTable value={influencers} paginator rows={25} filterDisplay="menu">
                    <Column field="userName" header="Username" filter={true} filterMatchMode="contains" ></Column>
                    <Column field="followers" header="Followers" ></Column>
                    <Column field="email" header="Email" filter={true} filterMatchMode="contains"></Column>
                    <Column  header="Engagement (%)" body={(rowData)=>{
                        let engagement = rowData.engagement?.slice(0,4);
                        return engagement;
                    }}></Column>
                    
                    <Column field="onboardPageCount" header="CTA" body={(data) => {
                        return data.onboardPageCount === null ? '0' : data.onboardPageCount;
                    }} sortable={true}></Column>
                    {invitationSent ?<Column field="emailCount" header="Reminder Count" sortable={true} body={(data) => {
                        return data.emailCount;
                    }}></Column> : <div></div> }
                    {!onboarded ? <Column header="" body={(rowData) => {
                        return <Button label='Email' onClick={() => {
                            let user = [];
                            user.push(rowData);
                            sendIndividualEmail(user);
                        }}></Button>
                    }}></Column> : mode === 'campaign' ? <Column header="" body={(rowData) => {
                        return <Button label='Invite' onClick={() => {
                            let user = [];
                            user.push(rowData);
                            sendInviteMail(user);
                        }}></Button>
                    }}></Column> : <div></div>}
                </DataTable>
                {mode === 'campaign' ? onboarded === true ? <Button className='p-button-primary' label='Send Campaign Invite' onClick={() => sendInviteMail()} /> : <div></div> :
                    onboarded === false ? <Button className='p-button-primary' label='Send Onboarding Email' onClick={() => sendMailCall()} /> : <div></div>}
            </div>

            <Sidebar visible={sidebar} onHide={() => showSidebar(false)} style={{ width: "30rem" }}>
                <div>
                    <h3>Add Filters</h3>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        searchByFilters();
                        showSidebar(false)
                    }}>

                        <div className="p-field">
                            <h4>Followers</h4>
                            <div className='flex'>
                                <InputText id="minFollowers" placeholder='Min.' className='p-inputtext p-d-block' value={minFollowers} onChange={(e) => setMinFollower(e.target.value)} />
                                <InputText id="maxFollowers" placeholder='Max.' className='p-inputtext p-d-block ml-2' value={maxFollowers} onChange={(e) => setMaxFollower(e.target.value)} />
                            </div>
                        </div>

                        <div className="p-field">
                            <h4>Email Subject</h4>
                            <div className='flex'>
                                <InputText id="" placeholder='Email Subject' className='p-inputtext p-d-block' value={emailSubjectFilter} onChange={(e) => setEmailSubjectFilter(e.target.value)} />
                            </div>
                        </div>

                        <div className="p-field">
                            <h4>Users Limit</h4>
                            <div className='flex'>
                                <InputText id="" placeholder='Number of users' className='p-inputtext p-d-block' value={userLimit} onChange={(e) => setUserLimit(e.target.value)} />
                            </div>
                        </div>

                        <div className='p-field'>
                            <h4>Status</h4>
                            <div className="p-field-radiobutton flex">
                                <RadioButton inputId="onboarded" value={true} name="onboarded" onChange={(e) => {
                                    setOnboarded(e.target.value);
                                    setInvitationSent(false)
                                    setInvitationNotSent(false)
                                }} checked={onboarded} />
                                <label htmlFor="onboarded"><b>On boarded</b></label>
                            </div>
                            <div className="p-field-radiobutton flex mt-2">
                                <RadioButton inputId="notOnboarded" value={false} name="notOnboarded" onChange={(e) => setOnboarded(e.target.value)} checked={!onboarded} />
                                <label htmlFor="notOnboarded"><b>Not on board</b></label>
                            </div>
                            <div className="p-field-radiobutton flex mt-2">
                                <RadioButton inputId="invitationSent" value={true} name="invitationSent" onChange={(e) => {
                                    setInvitationSent(e.target.value);
                                    setInvitationNotSent(false)
                                    setOnboarded(false);
                                }} checked={invitationSent} />
                                <label htmlFor="notOnboarded"><b>Invitation Email sent</b></label>
                            </div>
                            <div className="p-field-radiobutton flex mt-2">
                                <RadioButton inputId="invitationNotSent" value={true} name="invitationNotSent" onChange={(e) => {
                                    setInvitationSent(false);
                                    setInvitationNotSent(e.target.value)
                                    setOnboarded(false);
                                }} checked={invitationNotSent} />
                                <label htmlFor="notOnboarded"><b>Invitation Not sent</b></label>
                            </div>
                        </div>
                        <div>

                        </div>
                        <Button className='p-button-primary mt-4' label='Apply'></Button>
                    </form>

                    <h3>Set Email Subject</h3>
                    <InputText placeholder='Enter Email Subject' style={{width:"100%"}} value={mailSubject} onChange={(e)=> setMailSubject(e.target.value)}></InputText>
                </div>
            </Sidebar>

            <Dialog visible={fileUploadModal} onHide={()=>showFileUploadModal(false)}>  
                
                <input name='fileUpload' type='file' accept='.xlsx' onChange={onFileChange}></input>
                <Button className='p-button-danger' label='Upload' onClick={async ()=>{
                    let formData = new FormData();
                    formData.append('influencerList', selectedFile, `influencerList.xlsx`);
                    let response =await updateInfluencers(formData);
                    showFileUploadModal(false);
                }} ></Button>
            </Dialog>

        </Fragment>
    )
}


