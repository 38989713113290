import React from 'react'
import { Navbar } from './Navbar'

export const Admin = () => {
  return (
      <div>
          
      </div>
  )
}
