import React from 'react'
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authStateActions } from '../../store';
import { Menubar } from 'primereact/menubar';

export const NavbarInfluencer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.authState.isAuthenticated);
  const userId = useSelector(state => state.authState.userId);
  const leftContents = (
    <React.Fragment>
      <img src='https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png' height={40} width={100}></img>
      <Button label='Homepage' className='p-button-danger ml-4 mb-1' onClick={()=>{
        navigate(`/influencer/${localStorage.getItem('userId')}`)
      }}></Button>
      
    </React.Fragment>
  );

  const rightContents = (

    <div className=''>
    <i className='pi pi-fw pi-power-off mr-2 '></i>
    <span className='mr-2' onClick={ () =>{ 
    localStorage.removeItem('authToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('userType');
    dispatch(authStateActions.setAuthState({
        isAuthenticated: false,
        authToken: null,
        userId: null
      }))
    navigate('/login')
}}>Logout</span>
</div>

  );
  const items = [
]
  return (
    // <Toolbar left={leftContents} right={rightContents} />

    <Menubar style={{background:"black", color:"white"}}
    model={items} start={leftContents} end={rightContents}
/> 
  )
}
