import React, { useEffect, useState } from 'react'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useParams } from 'react-router-dom';
import { addFeedback, approvePost, downloadFile, getCampaignDetailsById, getContentListForCampaign } from './admin.http';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { Buffer } from 'buffer';

export const CampaignContent = (props) => {
    const [campaignDetails, setCampaignDetails] = useState({});
    const [contentList, setContentList] = useState([]);
    const [storyContentList, setStoryContentList] = useState([]);
    const [currentFile, setCurrentFile] = useState('');
    const [currentFileName, setCurrentFileName] = useState('')
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [caption, setcaption] = useState('');
    const [feedback, setFeedback] = useState('');
    const [influencer, setInfluencer] = useState({});
    const [postId, setPostId] = useState('');
    const params = useParams();

    const [insightsModal, showInsightsModal] = useState(false);
    const [insightsData, setInsightsData] = useState({});

    const fetchContent = async () => {
        let response = await getContentListForCampaign({ campaignId: params.id });
        if (response.status === 200) {
            setCampaignDetails(response.data);
            let data = response.data.contentList;
            // await contentHash(data);
            let id=[];
            let storyContent = data.filter(item => {
                if(item.postType === 'STORY' && !id.includes(item.influencerId)){
                    id.push(item.influencerId);
                    return item;
                }
            });
            setStoryContentList(storyContent);
            setContentList(data)
        }
    }

    const contentHash = async(data) => {
		for(const item of data){
			let responseHash = await downloadFile(item.filename);
			item['hash'] = responseHash.data;
		}
	}

    useEffect(() => {
        (async () => {
            await fetchContent();
        })();
    }, [])


    const onFileChange = event => {
        setSelectedFile(event.target.files[0]);
    };
    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    }

    // let url = `data:image/jpeg;base64,${hash}`;
    // let imgg = fetch(url).then(res => res.blob()).then(blob =>  {
    //     let file = new File([blob], 'name.jpeg',{type : 'image/png'});
    //     console.log(file);
    //     (async () =>{
    //         let buffer = await file.arrayBuffer();
    //         console.log(buffer);
    //         let base = Buffer.from(buffer).toString("base64");
    //         console.log(base);

    //     })();
    // })
    // console.log(imgg)
    return (
        <div className='p-8' >
            <div className="card ">
                <h2>Campaign Name : {campaignDetails.name}</h2>
                <h3>Campaign Description : {campaignDetails.description}</h3>

                <DataTable value={storyContentList} header="Story Posted" responsiveLayout="scroll">
                    <Column  header="Preview" body={(rowData)=>{
                        return <img src={`${window.SERVER_DATA.CDN_URL}/marketing-tech/inst-campaign-mgmt/${rowData.filename}`} alt="" height={200} width={300} />
                    }} ></Column>
                    <Column field='userName' header="User" sortable={true} ></Column>
                    <Column field='likes' header="Likes" ></Column>
                    <Column field='views' header="Views" ></Column>
                    <Column field='status' header="Status" ></Column>
                    <Column body={(rowData) => {
                        return rowData.status === 'LIVE' ?
                            <Button className='p-button-danger p-button-rounded' label='View Insights' onClick={async () => {
                                showInsightsModal(true);
                                setInsightsData({
                                    likes: rowData.likes,
                                    views: rowData.views,
                                    comments: rowData.comments
                                })
                            }}></Button>
                            : <Button className='p-button-danger p-button-rounded' label='View' onClick={async () => {
                                let response = await downloadFile(rowData.filename);
                                setCurrentFile(response.data);
                                setCurrentFileName(rowData.content);
                                setcaption(rowData.caption);
                                setShowFeedbackModal(true);
                                setInfluencer(rowData);
                                setPostId(rowData.id);
                            }}></Button>
                    }} ></Column>
                </DataTable>

                {/* <DataTable value={contentList} header="Content Posted" responsiveLayout="scroll">
                    <Column  header="Preview" body={(rowData)=>{
                        return <img src={`data:image/png;base64,${rowData.hash}`} alt="" height={200} width={300} />
                    }} ></Column>
                    <Column field='userName' header="User" ></Column>
                    <Column field='status' header="Status" ></Column>
                    <Column field='link' header="Link" ></Column>
                    <Column body={(rowData) => {
                        return rowData.status === 'LIVE' ?
                            <Button className='p-button-danger p-button-rounded' label='View Insights' onClick={async () => {
                                showInsightsModal(true);
                                setInsightsData({
                                    likes: rowData.likes,
                                    views: rowData.views,
                                    comments: rowData.comments
                                })
                            }}></Button>
                            : <Button className='p-button-danger p-button-rounded' label='View' onClick={async () => {
                                let response = await downloadFile(rowData.filename);
                                setCurrentFile(response.data);
                                setCurrentFileName(rowData.content);
                                setcaption(rowData.caption);
                                setShowFeedbackModal(true);
                                setInfluencer(rowData);
                                setPostId(rowData.id);
                            }}></Button>
                    }} ></Column>
                </DataTable> */}
                <Dialog visible={showFeedbackModal} onHide={() => setShowFeedbackModal(false)} >
                    <div className=''>
                        <h3>Content Posted</h3>
                        <img src={`data:image/jpeg;base64,${currentFile}`} alt="" height={200} width={400} />
                        <h4>Caption : </h4>
                        <InputTextarea value={caption} disabled={true}></InputTextarea>
                    </div>
                    <div className='mt-2'>
                        <h3>Feedback</h3>
                        <InputTextarea value={feedback} onChange={(e) => setFeedback(e.target.value)} ></InputTextarea>
                        <Button className='p-button-danger p-button-rounded ml-2' label='Add feedback'
                            onClick={async () => {
                                let data = {
                                    feedback,
                                    postId
                                }
                                let response = await addFeedback(data);
                                setShowFeedbackModal(false)
                                await fetchContent();
                            }}
                        ></Button>
                        <Button className='p-button-success p-button-rounded ml-2' label='Approve' onClick={async () => {
                            await approvePost({ postId });
                            setShowFeedbackModal(false)
                            await fetchContent();
                        }}></Button>
                    </div>


                </Dialog>

                <Dialog visible={insightsModal} onHide={() => showInsightsModal(false)} style={{ width: "50%" }} >
                    <div className=''>
                        <div className="card">
                            {console.log()}
                            <DataTable value={[insightsData]} header="Insights" >
                                <Column field="likes" header="Likes"></Column>
                                <Column field="comments" header="Comments"></Column>
                                <Column field="views" header="Views"></Column>
                            </DataTable>
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    )
}
