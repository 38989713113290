import { configureStore, createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";

const authState = createSlice({
    name : "authState",
    initialState : {
        isAuthenticated : false,
        authToken : null,
        userId : null,
        userType : null
    },
    reducers : {
        setAuthState(state,value) {
            state.isAuthenticated = value.payload.isAuthenticated;
            state.authToken = value.payload.authToken;
            state.userId = value.payload.userId;
            if(value.payload.authToken){
                state.userType  =jwt_decode(value.payload.authToken).type;
            }
            localStorage.setItem('userId',value.payload.userId);
            localStorage.setItem('authToken',value.payload.authToken);
            
        }
    }
})

const adminCampaignState = createSlice({
    name : 'adminCampaignState',
    initialState : {
        campaignId  : null, 
        campaignDetails : null,
        influencersList : null,
        showInviteInfluencerModal : false,
        inviteEmailSubject : '',
        inviteEmailBody : '',
        acceptEmailSubject : '',
        acceptEmailBody : ''

    },
    reducers : {
        setCampaignId(state,value) {
            state.campaignId = value.payload;
        },
        setInfluencersList(state,value) {
            state.influencersList = value.payload;
        },
        setShowInviteInfluencerModal (state,value) {
            state.showInviteInfluencerModal = value.payload;
        },
        setCampaignDetails(state,value){
            state.campaignDetails = value.payload;
        },
        setInviteEmailSubject(state,value){
            state.inviteEmailSubject = value.payload
        },
        setInviteEmailBody(state,value){
            state.inviteEmailBody = value.payload
        },
        setAcceptEmailSubject(state,value){
            state.acceptEmailSubject = value.payload
        },
        setAcceptEmailBody(state,value){
            state.acceptEmailBody = value.payload
        }

    }
})

const store = configureStore({
    reducer  : {
        authState :authState.reducer,
        adminCampaignState : adminCampaignState.reducer
    }
})

export const authStateActions = authState.actions;
export const adminCampaignStateActions = adminCampaignState.actions;

export default store;