import { axiosAuth } from '../../../axiosAuth';
export const sendInvoice = async(body)=>{
    try {
        let response = await axiosAuth.post( '/rest/api/v1/invoices/send-invoice',body);
        return response;
    } catch (error) {
        return error.response;
    }
}

export const getCampaignInvoices = async(campaignId)=>{
    try {
        let response = await axiosAuth.get( '/rest/api/v1/invoices/get-invoices/'+campaignId);
        return response;
    } catch (error) {
        return error.response;
    }
}
