import { useFormik } from 'formik'
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { login } from './auth.http';
import { RadioButton } from 'primereact/radiobutton';
import { authStateActions } from '../../store';
import { useDispatch } from 'react-redux';
import background from "./background.jpg";
import { Card } from 'primereact/card';
import './login.scss'
import { Toast } from 'primereact/toast';

export const Login = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [userType, setUserType] = useState('INFLUENCER');
    const toast = useRef(null);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validate: (data) => {
            let errors = {};
            if (!data.email) {
                errors.email = "Invalid Email";
            }
            if (!data.password) {
                errors.password = "Invalid Password";
            }
            return errors;
        },
        onSubmit: async (data) => {
            let response = await login(data, userType);
            if (response.status === 200) {
                localStorage.setItem('authToken', response.data.token);
                localStorage.setItem('userType', userType)
                localStorage.setItem('userId', response.data.userId);
                dispatch(authStateActions.setAuthState({
                    isAuthenticated: true,
                    authToken: response.data.token,
                    userId: response.data.userId
                }))
                if (userType === 'ADMIN') {

                    navigate('/admin/influencers')
                } else {
                    navigate('/influencer/' + response.data?.userId);
                }
            } else{
                await toast.current.show({severity:'error', summary: 'Invalid Credentials', life: 3000});
            }
        }
    })
    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    return (
        <div className='main' >
            <Toast ref={toast}></Toast>
            <div className='grid'>
                <div className='col-6 mt-4'>
                </div>
                <div className='col-12 md:col-6 lg:col-6'>
                    <div className='login-card'>
                        <div>
                            <img src='https://glancecdn.azureedge.net/assets/ui/images/desk_uploads/header_logo.png' height={50} style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "40%" }} />
                        </div>
                        <h2 style={{ textAlign: "center" }}>Login</h2>
                        <form className='p-fluid p-2' onSubmit={formik.handleSubmit}>
                            <div className='field' >
                                <InputText name="email" type="email" autoFocus placeholder='Email' value={formik.values.email} onChange={formik.handleChange} className={classNames({
                                    "p-invalid": isFormFieldValid("email"),
                                })} style={{ borderRadius: "10px" }} />

                                {getFormErrorMessage("email")}
                            </div>
                            <div className='field'>
                                <InputText type="password" name="password" placeholder='Password' value={formik.values.password} onChange={formik.handleChange} toggleMask className={classNames({
                                    "p-invalid": isFormFieldValid("password"),
                                })} style={{ borderRadius: "10px" }} />
                                {getFormErrorMessage("password")}
                            </div>
                            {/* <div className='flex '>
                                <div className="field-radiobutton">
                                    <RadioButton inputId="admin" name="admin" value="ADMIN" onChange={(e) => setUserType(e.value)} checked={userType === 'ADMIN'} />
                                    <label htmlFor="admin">Admin</label>

                                </div>
                                <div className="field-radiobutton pl-2">
                                    <RadioButton inputId="influencer" name="influencer" value="INFLUENCER" onChange={(e) => setUserType(e.value)} checked={userType === 'INFLUENCER'} />
                                    <label htmlFor="influencer">Influencer</label>
                                </div>
                            </div> */}
                            <div className='field' style={{ width: "40%", marginLeft: "0rem" }}>
                                <Button label='Login' type='submit' className='p-button-danger p-button-rounded'></Button>
                            </div>
                            <div className='field' style={{ width: "40%", marginLeft: "0.2rem",fontSize:"14px",cursor:"pointer" }}>
                                <span onClick={()=>{
                                    navigate('/forgot-password')
                                }}><u>Forgot Password?</u></span>
                            </div>
                            
                        </form>
                    </div>

                </div>
            </div>

        </div>

    )
}
